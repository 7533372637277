import React, { useEffect, useState } from "react"
import { xCompanyID, xTenant } from './../config.js'
import { LangFix } from '../functions'
import { BreadCrumbs } from "./../components/BreadCrumbs"

import './../assets/css/staticpages.css'

const requestOptions = {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Accept-Language': LangFix(localStorage.getItem('locale')),
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Tenant': xTenant,
        'X-CompanyID': xCompanyID,
        'Authorization': `Bearer ${localStorage.getItem('access.token')}`
    }
}

const ShowLoader = () => document.querySelector('.loader').classList.add('active')
const HideLoader = () => document.querySelector('.loader').classList.remove('active')

const GetDeliveryAddresses = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/entity-account-address/${localStorage.getItem('account.entity.id')}?include=company&realKey=true`
    const response = await fetch(URL, requestOptions)
    const json = await response.json()
    return json
}

const DeliveryAddressesContent = () => {

    const [tableData, setTableData] = useState([])

    useEffect(() => {
        ShowLoader()
        GetDeliveryAddresses()
            .then(json => {
                if (json.data) {
                    setTableData([])
                    const tempObj = {
                        AddressName: (json.data.company && json.data.company.data && json.data.company.data.Address1) || '',
                        CompanyName: (json.data.company && json.data.company.data && json.data.company.data.name) || '',
                        Street: (json.data.company && json.data.company.data && json.data.company.data.street_1) || '',
                        City: json.data.city,
                        PostalCode: json.data.postal_code || '',
                        Country: json.data.country || '',
                        current: 1,
                    }
                    setTableData(prevState => ([...prevState, tempObj]))
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }, [])
    return (
        <>
            <BreadCrumbs itemlist={[{ 'url': '/account/', 'title': 'My Account' }, { 'url': '/deliveryaddresses/', 'title': 'Delivery Addresses' }]} />
            <div className="account_content">
                <h1>Delivery Addresses</h1>

                <table className="table">
                    <thead>
                        <tr>
                            <th>Address Name</th>
                            <th>Company Name</th>
                            <th>City</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 && tableData.map((e, i) =>
                            <tr key={`deliveryaddressestable${i}`}>
                                <td>{e.AddressName}</td>
                                <td>{e.CompanyName}</td>
                                <td>{e.City}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export const DeliveryAddresses = () => {
    return (
        <div className="content">
            <DeliveryAddressesContent />
        </div>
    )
}