import React, { useEffect, useState }  from 'react'
import { t } from "i18next"
import { getRequestOptions } from '../../helpers/apiHelper'
import { ShowLoader, HideLoader } from '../../functions'

import '../../assets/css/checkout.css'

const GetDeliveryAddresses = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/entity-account-address/${localStorage.getItem('account.entity.id')}?include=company&realKey=true`
    const response = await fetch(URL,getRequestOptions())
    const json = await response.json()
    return json
}

const DeliveryAddress = () => {
    
    const [userData, setUserData] = useState({})
    
    useEffect(() => {
        ShowLoader()
        try {
            GetDeliveryAddresses().then(json => {
                setUserData({
                    companyname: json.data.matchcode || '',
                    street: json.data.street_1 || '',
                    postalcode: json.data.postal_code || '',
                    country: (json.data.company && json.data.company.data && json.data.company.data.CountryID) || '',
                    city: json.data.city,
                })
            })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    }, [])
    
    return (
        <>
            <div className="delivery_grey">
                <div className="delivery_grey__header">{t('niki.b2b.deliveryaddresses.billingaddress')}</div>
                <div className="delivery_grey__unit">
                    <strong>{t('niki.b2b.deliveryaddresses.companyname')}:</strong> {userData.companyname}
                </div>
                {localStorage.getItem('local.tax.number') &&
                    <div className="delivery_grey__unit">
                        <strong>{t('niki.b2b.deliveryaddresses.vatid')}:</strong> {localStorage.getItem('local.tax.number')}
                    </div>
                }
                <div className="delivery_grey__unit">
                    <strong>{t('niki.b2b.deliveryaddresses.billingaddress')}</strong>
                    {userData.street}<br />
                    {userData.city}<br />
                    {userData.postalcode}<br />
                    {userData.country}
                </div>
                {/* <button className="btn">{t('niki.b2b.cartflow.editbusinessaddress')}</button> */}
            </div>
            <div className="delivery_grey">
                <div className="delivery_grey__header">{t('niki.b2b.cartflow.deliveryaddress')}</div>
                <div className="delivery_grey__unit">
                    <strong>{t('niki.b2b.deliveryaddresses.companyname')}:</strong> {userData.companyname}
                </div>
                <div className="delivery_grey__unit">
                    <strong>{t('niki.b2b.cartflow.deliveryaddress')}</strong>
                    {userData.street}<br />
                    {userData.city}<br />
                    {userData.postalcode}<br />
                    {userData.country}
                </div>
            </div>
        </>
    )
}

export default DeliveryAddress;