import React, { useState, useEffect, useRef } from "react"
import { t } from "i18next"
import { useSearchParams } from 'react-router-dom'
import { InstantSearch, Configure, SearchBox, connectInfiniteHits } from 'react-instantsearch-dom'

import { xCompanyID, searchClient, startFacetFilters, priceLineID } from '../config.js'
import { translationsForSearchBox } from '../functions'
import { getMeilisearchIndexName } from '../helpers/apiHelper'
import { BreadCrumbs } from "../components/BreadCrumbs"
import { Product, ProductPlaceholder } from "../components/ProductCard"

import './../assets/css/searchsingleproduct.css'

const ProductsPerPage = 20

const CustomInfiniteHits = connectInfiniteHits(({ hits, hasMore, refineNext }) => {
    
    const loaderRef = useRef(null)
    
    const [isFetching, setIsFetching] = useState(false)

    const handleScroll = () => {
        if (!hasMore || isFetching) return
        if (loaderRef.current && loaderRef.current.getBoundingClientRect().top <= window.innerHeight) {
            setIsFetching(true)
        }
    }
  
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
        // eslint-disable-next-line
    }, [hits, isFetching, hasMore])
  
    useEffect(() => {
        if (!isFetching) return
        refineNext()
        setIsFetching(false)
        // eslint-disable-next-line
    }, [isFetching])

    return (
        <>
            <div className="products_grid paddingbottom0">
                {hits.map(hit => (
                    <Product key={`product${hit.id}`} {...hit} pricelineId={priceLineID} isGrid={true}/>
                ))}
            </div>
            {hasMore && <div ref={loaderRef} className="products_grid paddingbottom0"><ProductPlaceholder elements={ProductsPerPage} /></div>}
        </>
    )
})

const SearchContent = props => {
    
    const defaultSearchQuery = props.query || ''

    const [query,setQuery] = useState(props.query || '')

    const ChangeSearchValue = e => {
        const localValue = e.target.value
        setQuery(localValue)
        const currentURL = new URL(window.location.href)
        currentURL.searchParams.set('q', localValue)
        window.history.replaceState(null, '', currentURL)
    }

    return (
        <>
            <BreadCrumbs itemlist={[{url: `/search/?q=${query}`, title: `${t('niki.b2b.header.search')} «${query}»`},]} />
            <InstantSearch
                indexName={getMeilisearchIndexName("items")}
                searchClient={searchClient}
            >
                <div className="search_content">
                    <h1>{t('niki.b2b.search.h1')} &laquo;{query}&raquo;</h1>
                    <SearchBox
                        placeholder={t('niki.b2b.header.searchproducts')}
                        onChange={ChangeSearchValue}
                        defaultRefinement={defaultSearchQuery}
                        translations={translationsForSearchBox()}
                    />
                </div>
                <Configure
                    facetFilters={startFacetFilters}
                    hitsPerPage={ProductsPerPage}
                    attributesToHighlight={[]}
                    attributesToRetrieve={['id','ItemExtrafieldsAnswers','main_unit_code_translations','sale_unit_code_translations','company_id','entity_account_id','tax_group_id','source_id','status_id','attachment_id','source_item_id','source_family_id','source_supplier_id','source_item_type_id','source_barcode_type_id','main_unit_code','sale_unit_code','purchase_unit_code','barcode','matchcode','capacity','capacity_unit_code','width','height','length','netweight','grossweight','properties','order_apply_round','order_round_percent','order_round_unit_code','family','itemStocks',`itemPrices.${priceLineID}`,`itemDiscounts.${priceLineID}`,'is_booklet','is_new','itemUnitBarcodeByUnitCodeBox','ratings','attachment','attachments','is_discount','default_warehouse','is_in_stock','itemDescriptions',`${xCompanyID === '4' ? 'brand' : 'trademark' }`,'country']}
                />
                <CustomInfiniteHits />
            </InstantSearch>

        </>
    )
}

export const Search = () => {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const query = searchParams.get("q")
    return (
        <div className="content">
            <SearchContent query={query} />
        </div>
    )
}