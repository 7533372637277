import React, { useEffect, useRef, useState } from "react"
import { NavLink } from 'react-router-dom'
import { t } from "i18next"
import { ShowLoader, HideLoader, FixCompanyName, FetchPreferencesPost, returnLangForFlag } from "../functions.js"
import { Languages, languagesArray } from "../config.js"
import { getRequestOptions, getAllCartsData } from '../helpers/apiHelper'

import { BreadCrumbs } from "./../components/BreadCrumbs"
import { TwoContacts } from "../components/TwoContacts"

import './../assets/css/account.css'

import { ReactComponent as InvoicesReturns } from './../assets/svg/accountinvoicesreturns.svg'
import { ReactComponent as Debts } from './../assets/svg/accountdebts.svg'
import { ReactComponent as Orders } from './../assets/svg/accountorders.svg'
import { ReactComponent as ShoppingCarts } from './../assets/svg/accountshoppingcarts.svg'
import { ReactComponent as Favorites } from './../assets/svg/accountfavorites.svg'
import { ReactComponent as Marketing } from './../assets/svg/accountmarketing.svg'
import { ReactComponent as Feedback } from './../assets/svg/accountfeedback.svg'

const lang = localStorage.getItem('locale')

const LoadDebtsData = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/entity-ledgers?search=entity_account_id%3A${localStorage.getItem('account.entity.id.real')}&realKey=1`
    const response = await fetch(URL,getRequestOptions())
    const json = await response.json()
    return json
}

export const Account = () => {

    const LangSelectorEl = useRef(null)
    const [numberCarts,setNumberCarts] = useState(0)
    const [numberDebts,setNumberDebts] = useState(0)
    useEffect(()=>{
        try {
            ShowLoader()
            getAllCartsData(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id')}`)
                .then(json => json.data && setNumberCarts(parseInt(json.data.length)))
        } catch (error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    },[])
    useEffect(()=>{
        try {
            ShowLoader()
            LoadDebtsData().then(json => json.data && setNumberDebts(parseInt(json.data.length)))
        } catch (error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    },[])

    const [selectedLang,setSelectedLang] = useState({})
    const ShowLangSelector = () => LangSelectorEl.current.classList.add('active')
    const HideLangSelector = () => LangSelectorEl.current.classList.remove('active')
    const SelectLang = e => {
        setSelectedLang({flag: e.target.dataset.flag, country: e.target.dataset.country})
        localStorage.setItem('locale',e.target.dataset.flag)
        localStorage.setItem('locale.full',e.target.dataset.country)
        ShowLoader()
        FetchPreferencesPost(e.target.dataset.flag)
        .catch(error => console.error(error))
        .finally(() => {
            document.location.reload()
            HideLoader()
        })
    }

    return (
        <div className="content">
            <BreadCrumbs itemlist={[{'url':'/account/','title':t('niki.b2b.menu.myaccount')}]} />
            <div className="account_content">
                <h1>{t('niki.b2b.menu.myaccount')}</h1>
                <div className="account_hello">{t('niki.b2b.account.hello')}, {localStorage.getItem('account.user.name')}</div>
                <div className="account_under_hello">{FixCompanyName(t('niki.b2b.account.welcome'))}</div>
                <ul className="account_grid">
                    <li><NavLink to="/invoices/"><InvoicesReturns />{t('niki.b2b.menu.invoicesreturns')}</NavLink></li>
                    <li><NavLink to="/debts/"><Debts />{t('niki.b2b.account.debts')}{numberDebts > 0 && <span className="alert">{numberDebts}</span>}</NavLink></li>
                    <li><NavLink to="/orders/"><Orders />{t('niki.b2b.account.orders')}</NavLink></li>
                    <li><NavLink to="/shoppingcarts/"><ShoppingCarts />{t('niki.b2b.cart.shoppingcarts')}{numberCarts > 0 && <span className="alert">{numberCarts}</span>}</NavLink></li>
                    <li><button onClick={ShowLangSelector}><span className={[`account_lang`,`flag-icon-${returnLangForFlag(lang)}`].join(' ')}></span>{t('niki.b2b.account.language')}<span className="selected_lang">{t(languagesArray[lang])}</span></button></li>
                    <li><NavLink to="/all-products/?show=favorites"><Favorites />{t('niki.b2b.menu.favorites')}</NavLink></li>
                    <li><NavLink to="/marketing/"><Marketing />{t('niki.b2b.menu.marketing')}</NavLink></li>
                    <li><NavLink to="/feedback/"><Feedback />{t('niki.b2b.menu.feedback')}</NavLink></li>
                </ul>
                <div className="your_designated_support_team">{t('niki.b2b.account.supportteam')}</div>
                <TwoContacts />
            </div>
            <div className="account__lang_selector__wrapper" ref={LangSelectorEl}>
                <div className="account__lang_selector__overlay" onClick={HideLangSelector}></div>
                <ul className="lang_selector__list">
                {Languages.map((e,i)=>
                    <li
                        key={`language${i}`}
                        onClick={SelectLang}
                        data-flag={e.flag}
                        data-country={e.country}
                        className={e.flag === selectedLang.flag ? 'current' : 'regular'}
                    >
                        <span className={['lang_selector__flag',`flag-icon-${e.flag}`].join(' ')}></span>{t(e.translateCountry)}
                    </li>)}
                </ul>
            </div>
        </div>
    )
}