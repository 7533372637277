import React, { useEffect, useRef, useState } from "react"
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { t } from "i18next"
import { priceLineID } from '../config.js'
import { PriceFormat, ShowLoader, HideLoader, DataPreloader } from "../functions.js"
import { getRequestOptions } from '../helpers/apiHelper'
import { setFormShowOrder } from '../reduxToolkit/productsSlice'

import { BreadCrumbs } from "../components/BreadCrumbs"

import './../assets/css/invoices.css'

import { ReactComponent as Process } from './../assets/svg/invoiceprocess.svg'
import { ReactComponent as ViewOrder } from './../assets/svg/invoicevieworder.svg'
import { ReactComponent as Return } from './../assets/svg/invoicererturn.svg'

const DateFormat = localDate => localDate && localDate.length > 0 && localDate.split(' ')[0].replaceAll('/', '.')

export const Invoices = () => {
    const navigate = useNavigate()
    const invoicesPopupEl = useRef(null)
    const dispatch = useDispatch()

    const passed14Days = new Date().getTime() - 1209600000
    const [dataLoaded, setDataLoaded] = useState(false)
    const [invoicesData, setInvoicesData] = useState([])

    const ExplandCollapseInvoice = e => {
        const InvoiceSelector = `.invoice.${e.target.dataset.class}`
        document.querySelector(InvoiceSelector).classList.toggle('active')
    }
    const ReturnClick = e => {
        const ReturnStatus = e.target.dataset.status
        const uuid = e.target.dataset.uuid
        ReturnStatus === 'disabled' && ShowPopupAlert()
        if (ReturnStatus === 'enabled') navigate(`/returnproducts/${uuid}/`)
    }
    const ShowPopupAlert = () => invoicesPopupEl.current.classList.add('active')
    const HidePopupAlert = () => invoicesPopupEl.current.classList.remove('active')

    useEffect(() => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents-invoices?include=details,status,documentType,paymentType,paymentMethod&showVat=true&realKey=true&page=1&search=documentType.id:1;account_id:${localStorage.getItem('account.entity.id.real')};key:FAC2,FAC,NC,NCW,SRET&searchFields=key:in;account_id:in;documentType.id:in&limit=${100}&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                setInvoicesData([])
                setDataLoaded(true)
                json.data && json.data.forEach(e => {
                    const tempObj = {
                        id: e.id,
                        idReal: e.id_real,
                        invoice: `${e.key} ${e.serial} - ${String(e.number).padStart(6, '0')}`,
                        date: DateFormat(e.created_at),
                        status: e.status && e.status.data && e.status.data.matchcode,
                        processID: e.process_id,
                        processIDReal: e.process_id_real,
                        SKUs: (e.details && e.details.data && e.details.data.length) || 0,
                        Gross: e.total_gross_amount,
                        Net: e.total_net_amount,
                        VAT: e.total_tax_amount,
                        returnStatus: 'enabled',
                        uuid: e.uuid,
                    }
                    setInvoicesData(prevState => ([...prevState, tempObj]))
                })
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }, [])

    return (
        <div className="content">
            <BreadCrumbs itemlist={[{ 'url': '/account/', 'title': t('niki.b2b.menu.myaccount') }, { 'url': '/invoices/', 'title': t('niki.b2b.menu.myinvoices') }]} />
            <div className="invoices_content">
                <h1>{t('niki.b2b.menu.myinvoices')}</h1>
                <div className="invoices">
                    {!dataLoaded && <DataPreloader />}
                    {dataLoaded && invoicesData.length > 0 && invoicesData.map((e, i) =>
                        <div className={[`invoice`, `id${e.id.toLowerCase().replace(' ', '_')}`].join(' ')} key={`invoice${i}`}>
                            <div className="invoice__first">
                                <strong>{t('niki.b2b.debts.invoice')}</strong>
                                <strong>{t('niki.b2b.product.date')}</strong>
                                <strong className="right">{PriceFormat(e.Net)}</strong>
                                <span>{e.invoice}</span>
                                <span>{e.date}</span>
                                {/* 
                                // todo
                                <div className="right"><span className={[`invoice__first__status`,e.status.toLowerCase()].join(' ')}>{e.status}</span></div> 
                                */}
                                <button
                                    className="invoice__first__button"
                                    data-class={`id${e.id.toLowerCase().replace(' ', '_')}`}
                                    onClick={ExplandCollapseInvoice}
                                ></button>
                            </div>
                            <div className="invoice__second">
                                <div className="invoice__second__info">
                                    {t('niki.b2b.debts.processid')}: {e.processIDReal}
                                    <div className="invoice__second__info__three_columns">
                                        <span>{t('niki.b2b.debts.skus')}: {e.SKUs}</span>
                                        <span>{t('niki.b2b.product.gross')} {PriceFormat(e.Gross)}</span>
                                        <span>{t('niki.b2b.product.vat')}: {PriceFormat(e.VAT)}</span>
                                    </div>
                                </div>
                                <div className="invoice__second__buttons">
                                    {e.processIDReal
                                        ? <NavLink to={`/process/${e.processID}/`} className="invoice__second__button"><Process />{t('niki.b2b.process.h1')}</NavLink>
                                        : <div className="invoice__second__button disabled"><Process />{t('niki.b2b.process.h1')}</div>
                                    }
                                    <NavLink to={`/document/${e.uuid}/`} onClick={() => { dispatch(setFormShowOrder('invoices')) }} className="invoice__second__button"><ViewOrder />{t('niki.b2b.debts.invoice')}</NavLink>
                                    {(passed14Days < new Date(e.date.split('.').reverse().join('.')).getTime())
                                        ? <button
                                            className={[`invoice__second__button`, e.returnStatus].join(' ')}
                                            onClick={ReturnClick}
                                            data-uuid={e.uuid}
                                            data-status={e.returnStatus}
                                        ><Return />{t('niki.b2b.invoices.return')}</button>
                                        : <div className="invoice__second__button disabled"><Return />{t('niki.b2b.process.h1')}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="invoices_popup__wrapper" ref={invoicesPopupEl}>
                    <div className="invoices_popup">
                        <div className="invoices_popup__header">{t('niki.b2b.invoices.returndisabled')}</div>
                        <div className="invoices_popup__text">{t('niki.b2b.invoices.returndisabledreason')}</div>
                        <button className="invoices_popup__close" onClick={HidePopupAlert} type={'button'}></button>
                    </div>
                    <div className="invoices_popup__overlay" onClick={HidePopupAlert}></div>
                </div>
            </div>
        </div>
    )
}