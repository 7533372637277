import { xCompanyID, meilisearchUrl, xTenant } from '../config'
import { ShowLoader, HideLoader, CheckTokenLifeTime } from '../functions.js'
import { setIsOpenNoCartAlert, setIsOpenProductNotFoundAlert } from '../reduxToolkit/allproduct'

export const includeForProduct = 'item,item.itemCategories,item.entityAccount,item.itemDescriptions,item.attachments,item.itemPrices,item.itemDiscounts,item.itemKinds,item.itemStocks,item.itemFamily'
const LangFix = e => e === 'gb' ? 'en' : e

export const getRequestOptions = (method = 'GET', accept, contentType) => {
    return {
        method: method,
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${localStorage.getItem('access.token')}`
        }
    }
}

export const getRequestApi = async ({ method, accept, contentType, URL, body = null }) => {
    const headers = {
        method: method ? method : 'GET',
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${localStorage.getItem('access.token')}`
        },
        body
    }

    let response = {}
    try {
        const result = await fetch(URL, headers)
        if (result.status === 401) {
            response = result
            CheckTokenLifeTime()
        } else {
            response = result
        }
    } catch (error) {
        console.error(error)
    }

    return response
}

export const getRequestOptionsNoAuth = (method = 'GET', accept, contentType) => {
    return {
        method: method,
        headers: {
            'Accept': accept ? accept : 'application/json',
            'Accept-Language': LangFix(localStorage.getItem('locale')),
            'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded',
            'X-Tenant': xTenant,
            'X-CompanyID': xCompanyID,
        }
    }
}

export const fetchDataPhoneOrEmail = async (url, requestOptions) => {
    const response = await fetch(url, requestOptions)
    return response.ok
}

export const getBanners = async (item_segmentations = '') => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/contents?include=contentType,attachments,items${item_segmentations}`, getRequestOptionsNoAuth())
    const json = await response.json()
    return json
}

export const deleteProductFormCart = (productId) => {
    ShowLoader()
    fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${productId}`, getRequestOptions('DELETE'))
        .then(response => {
            // console.log(response)
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
}

export const updateProductInCartPost = async (productId, qty) => {
    const requestOptions = getRequestOptions('POST', null, 'application/json')
    requestOptions.body = JSON.stringify({ qty: qty })

    ShowLoader()
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details/${productId}`, requestOptions)
    const json = await response.json()
    HideLoader()
    return json
}

export const getProductFormSearch = async (codes) => {
    const filterInsert = `(barcode=${codes} OR source_item_id=${codes}) AND company_id=${xCompanyID}`
    const response = await fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("items")}/search?filter=${encodeURIComponent(filterInsert)}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CompanyID': xCompanyID,
            'Authorization': `Bearer ${process.env.REACT_APP_MEILISEARCH_SECRET_KEY}`
        }
    })
    const json = await response.json()
    return json
}

export const deleteCartsItem = async cartItemID => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${cartItemID}`
    const response = await fetch(URL, getRequestOptions('DELETE'))
    return response
}

export const getAllCartsData = async (url) => {
    const response = await fetch(url, getRequestOptions())
    const json = await response.json()
    return json
}

export const getAccountBlocked = async (accountEntityId) => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/entity-account-check/${accountEntityId ? accountEntityId : localStorage.getItem('account.entity.id')}`
    const response = await fetch(URL, getRequestOptions())
    const json = await response.json()
    // return {blocked: true}
    return json
}

export const returnErrorDuringAddProductToCart = (error, dispatch) => {
    if (localStorage.getItem('cart.uuid')) {
        getAllCartsData(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id.real')}`)
            .then(json => {
                let existCart = false
                json.data && json.data.forEach(e => {
                    existCart = e.uuid === localStorage.getItem('cart.uuid') && true
                })
                existCart
                    ? dispatch(setIsOpenProductNotFoundAlert(true))
                    : dispatch(setIsOpenNoCartAlert(true))
            })
            .catch(error => {
                dispatch(setIsOpenProductNotFoundAlert(true))
                console.error(error)
            })
    } else {
        dispatch(setIsOpenProductNotFoundAlert(true))
    }
    console.error(error)
}

export const createNewCart = async (newCartName) => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers`
    const params = {
        company_id: localStorage.getItem('account.company.hashed'),
        customer_account_id: localStorage.getItem('account.entity.id'),
        status_id: 'J8RvMwowm7k2pNOr',
        realKey: '1',
    }

    if (newCartName) params.matchcode = newCartName
    const body = JSON.stringify(params)

    const response = getRequestApi({ URL, method: 'POST', contentType: 'application/json', body })
    return response
}

export const getEntityData = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/user/entity-accounts?include=company,user&realKey=true&type_id=8&itemSegmentations=true`, getRequestOptions())
    const json = await response.json()
    return json
}

export const cartHeaderInclude = ['cartDetails', 'cartDetails.item', 'cartDetails.sale_unit_code', 'cartDetails.item.itemDescriptions', 'cartDetails.item.itemPrices', 'cartDetails.item.itemDiscounts', 'cartDetails.item.attachments', 'cartDetails.item.itemKinds', 'cartDetails.item.itemStocks', 'cartDetails.item.itemFamily', 'cartDetails.item.entityAccount']

export const getMeilisearchIndexName = (indexName) => `${xTenant}_${xCompanyID}_${indexName}`