import React, { useEffect, useRef, useState } from "react"
import { t } from "i18next"
import { ShowLoader, HideLoader } from './../functions'
import { getRequestOptions } from '../helpers/apiHelper'

import { BreadCrumbs } from "./../components/BreadCrumbs"
import LoadableImage from "../components/LoadableImage/LoadableImage"

import './../assets/css/staticpages.css'

const YoutubeID = url => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    var match = url.match(regExp)
    return (match&&match[7].length===11)? match[7] : false
}

const GetVideos = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/content-videos`,getRequestOptions())
    const json = await response.json()
    return json
}

const attachmentSubID = 'gOkj31nzQol9e5EQ'
const GetImage = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/contents?include=attachments&content_type_id=21P0praYMaJGEYLx&content_subtype_id=gOkj31nzQol9e5EQ`,getRequestOptions())
    const json = await response.json()
    return json
}

export const Videos = () => {

    const videoWrapperEl = useRef(null)

    const [videosData, setVideosData] = useState([])
    const [currentVideo, setCurrentVideo] = useState('')
    const [currentVideoTitle, setCurrentVideoTitle] = useState('')
    const [pageImage, setPageImage] = useState({ simple: '', mime: '', webp: '' })
    const [isDowandImage, setIsDowandImage] = useState(true)

    const ExplandVideo = (video, title) => {
        setCurrentVideo(video)
        setCurrentVideoTitle(title)
        videoWrapperEl.current.classList.add('active')
    }
    const HideVideo = () => {
        setCurrentVideo('')
        videoWrapperEl.current.classList.remove('active')
    }

    const youtube = (localStorage.getItem('socials') && localStorage.getItem('socials') !== 'undefined') ? JSON.parse(localStorage.getItem('socials'))?.filter(i => i.matchcode === 'Youtube') : null

    useEffect(()=>{
        try {
            ShowLoader()
            GetVideos()
            .then(json => {
                setVideosData([])
                json.data && json.data.forEach(e => {
                    const videoID = YoutubeID(e.url)
                    const tempObj = {
                        url: e.url.replace('/watch?v=','/embed/'),
                        id: videoID,
                        thumb: `http://img.youtube.com/vi/${videoID}/0.jpg`,
                        title: e.matchcode,
                    }
                    setVideosData(prevState => ([...prevState, tempObj]))
                })
            })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    },[])

    useEffect(()=>{
        try {
            let simpleImg = ''
            let simpleMime = ''
            let webpImg = ''
            ShowLoader()
            GetImage().then(json => {
                json.data && json.data.forEach(e => {
                    if (e.id === attachmentSubID && e.attachments && e.attachments.data && e.attachments.data[0] && e.attachments.data[0].links) {
                        e.attachments.data[0].links.forEach(el => {
                            if (el.template === 'original') {
                                if (el.mimetype === 'image/webp') {
                                    webpImg = el.url
                                } else {
                                    simpleImg = el.url
                                    simpleMime = el.mimetype
                                }
                            }
                        })
                        setPageImage({
                            simple: simpleImg,
                            mime: simpleMime,
                            webp: webpImg,
                        })
                    }
                })
                setIsDowandImage(false)
            })
        } catch (e) {
            setIsDowandImage(false)
            console.error(e)
        } finally { 
            HideLoader()
        }
    },[])

    const YoutubePlayer = () => {
        return (
            <iframe
                src={currentVideo}
                title={currentVideoTitle}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        )
    }

    return (
        <div className="content">
            <BreadCrumbs itemlist={[{'url':'/marketing/','title':t('niki.b2b.menu.marketing')},{'url':'/videos/','title':t('niki.b2b.marketing.videos')}]} />
            <div className="videos_content">
                <h1>{t('niki.b2b.marketing.videos')}</h1>

                {isDowandImage || pageImage.simple
                    ? <picture>
                        <source type="image/webp" srcSet={pageImage.webp} />
                        <source type={pageImage.mime} srcSet={pageImage.simple} />
                        {/* <img src={pageImage.simple} alt="Video placeholder" className="main_video_placeholder" /> */}
                        <LoadableImage src={pageImage.simple} alt={t('niki.b2b.marketing.videos')} height={'183px'} styleContainer={{ marginBottom: '15px', borderRadius: 10 }} />
                    </picture>
                    : null
                }

                <h2>{t('niki.b2b.videos.updates')}</h2>
                <div className="videos_content__description">{t('niki.b2b.videos.text1')}.<br />
                {t('niki.b2b.videos.text2')}.</div>

                {youtube && <div className="marginbottom40"><a className="btn btn-info" href={localStorage.getItem('social.youtube')} target="_blank" rel="noopener noreferrer nofollow">{t('niki.b2b.videos.followyoutube')}</a></div>}

                <h2 className="center marginbottom40">{t('niki.b2b.videos.archive')}</h2>
                <ul className="videos_content__list">
                {videosData.length > 0 && videosData.map((e,i) =>
                    <li key={`videoscontent${i}`} onClick={() => ExplandVideo(e.url, e.title)}>
                        {/* <img src={e.thumb} alt={e.title} /> */}
                        <LoadableImage  src={e.thumb} alt={e.title} height={'120px'}/>
                    </li>
                )}
                </ul>

                {/*
                <div className="center marginbottom40"><button className="btn btn-info">Load More Videos</button></div>
                */}

            </div>

            <div className="video_wrapper" ref={videoWrapperEl}>
                <div className="video_overlay" onClick={HideVideo}></div>
                <div className="video_content"><YoutubePlayer /></div>
            </div>
        </div>
    )
}