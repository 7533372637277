import React, { useEffect, useState }  from 'react'
import { t } from "i18next"
import { getRequestOptions } from '../helpers/apiHelper'
import { ShowLoader, HideLoader } from '../functions'

import { ReactComponent as Phone } from './../assets/svg/twocontactsphone.svg'
import { ReactComponent as Mail } from './../assets/svg/twocontactsmail.svg'

export const TwoContacts = () => {

    const [contactsData,setContactsData] = useState([])
    useEffect(()=>{
        ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/entity-accounts/${localStorage.getItem('account.entity.id')}/company-contacts`,getRequestOptions())
            .then(response => response.json())
            .then(json => {
                setContactsData([])
                json.data.users && json.data.users.forEach(e => {
                    const tempObj = {
                        name: e.name,
                        phone: e.phone,
                        image: e.picture.medium,
                        email: e.email,
                        position: e.position,
                    }
                    setContactsData(prevState => ([...prevState, tempObj]))
                })
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    },[])

    return (
        <div className="two_contacts">
            {contactsData && contactsData.length > 0 && contactsData.map((e,i) => 
                <div className="one_contact" key={`onecontact${i}`}>
                    <div className="one_contact__info">
                        <div><img src={e.image} alt={e.name} className="one_contact__info__image" /></div>
                        <div>
                            <div className="one_contact__info__name">{e.name}</div>
                            <div className="one_contact__info__works">{e.position}</div>
                            <div className="one_contact__info__email"><a href={`mailto:${e.email}`}>{e.email}</a></div>
                            <div className="one_contact__info__phone"><a href={`tel:${e.phone}`}>{e.phone}</a></div>
                        </div>
                    </div>
                    <div className="one_contact__info__buttons">
                        <a className="btn" href={`tel:${e.phone}`}>{t('niki.b2b.contact.callnow')}<Phone /></a>
                        <a className="btn btn-info" href={`mailto:${e.email}`}>{t('niki.b2b.login.email')}<Mail /></a>
                    </div>
                </div>
            )}
        </div>
    )
}