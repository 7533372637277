import React, { useState, useEffect, useRef } from "react"
import { NavLink } from 'react-router-dom'
import { t } from "i18next"
import { ShowLoader, HideLoader, scrollTop } from "../functions.js"
import { getRequestOptions } from '../helpers/apiHelper'

import { BreadCrumbs } from "../components/BreadCrumbs"

import './../assets/css/brands.css'

import { ReactComponent as Facebook } from './../assets/svg/facebook.svg'
import { ReactComponent as Linkedin } from './../assets/svg/linkedin.svg'
import { ReactComponent as Instagram } from './../assets/svg/instagram.svg'
import { ReactComponent as Home } from './../assets/svg/homegreyfilled.svg'
import { ReactComponent as Arrow } from './../assets/svg/brandsarrow.svg'

const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","0","1","2","3","4","5","6","7","8","9"]
let letterLength = []
let brandsDesc = {}
let brandsFacebook = {}
let brandsLinkedin = {}
let brandsInstagram = {}
let brandsWebsite = {}

const fetchStartData = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/brand-list`,getRequestOptions())
    const json = await response.json()
    return json
}

const getHighlightedText = (text, highlight) => {
    if (text) {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
        return <span> { parts.map((part, i) => 
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { background: '#FFE600' } : {} }>
                { part }
            </span>)
        } </span>
    }
}

export const Brands = () => {

    const abcEl = useRef(null)
    const abcOverlayEl = useRef(null)
    const abcButtonEl = useRef(null)

    const [weSearch, setWeSearch] = useState('')
    const [dataLoaded, setDataLoaded] = useState(false)
    const [pageHeader, setPageHeader] = useState('Brands')
    const [brands, setBrands] = useState([])

    useEffect(() => {
        ShowLoader()
        let brandsAlphabet = []
        fetchStartData().then(json => {
            json.data.forEach((el)=>{
                const brandName = el.name.trim()
                brandsAlphabet.push(brandName)
                if (el.description && el.description[0]) brandsDesc[brandName] = el.description[0].replace(/<\/?[a-zA-Z]+>/gi,'')
                if (el.socials) {
                    el.socials.forEach(social => {
                        if (social.matchcode === 'Website') brandsWebsite[brandName] = social.value
                        if (social.matchcode === 'Facebook') brandsFacebook[brandName] = social.value
                        if (social.matchcode === 'Instagram') brandsInstagram[brandName] = social.value
                        if (social.matchcode === 'Linkedin') brandsLinkedin[brandName] = social.value
                    })
                }
            })
            brandsAlphabet.sort()
            alphabet.forEach(letter=>{
                letterLength[letter] = brandsAlphabet.filter(brand => brand.startsWith(letter)).length
            })
            setBrands(brandsAlphabet)
        })
        .finally(() => {
            HideLoader()
            setDataLoaded(true)
        })
        scrollTop()
    }, [])

    const ShowHideABC = () => {
        abcEl.current.classList.toggle('active')
        abcButtonEl.current.classList.toggle('active')
        abcOverlayEl.current.classList.toggle('active')
    }

    const HideABCOverlay = () => {
        abcEl.current.classList.remove('active')
        abcButtonEl.current.classList.remove('active')
        abcOverlayEl.current.classList.remove('active')
    }

    const SearchBrand = e => {
        const WhatFind = e.target.value.trim().toLowerCase()
        setWeSearch(WhatFind)
        if (WhatFind.length > 0) {
            document.querySelectorAll('.brands__big_letter').forEach(e => e.style.display = 'none')
            document.querySelectorAll('.brands__single_brand').forEach(e => e.style.display = 'none')
            document.querySelectorAll(`.brands__single_brand[data-search*="${WhatFind}"]`).forEach(e => e.style.display = '')
            setPageHeader(`${t('niki.b2b.search.h1')}: ${WhatFind}`)
        } else {
            document.querySelectorAll('.brands__big_letter').forEach(e => e.style.display = '')
            document.querySelectorAll('.brands__single_brand').forEach(e => e.style.display = '')
            setPageHeader(t('niki.b2b.menu.brands'))
        }
    }

    const ToggleBrand = e => {
        const ParentEl = e.target.parentNode
        ParentEl.classList.toggle('active')
    }

    return (
        <div className="content brands_bottom">
            <BreadCrumbs itemlist={[{'url':'/marketing/','title':t('niki.b2b.menu.marketing')},{'url':'/brands/','title':t('niki.b2b.menu.brands')}]} />
            <div className="brands_content">
                <h1>{pageHeader}</h1>
            </div>
            {dataLoaded
                ? <div className="brands_letters">{alphabet.filter(el => letterLength[el] > 0).map((e,i)=>
                    <div id={e} key={`letterblock${i}`} className="brands_by_letter__wrapper">
                        <div className="brands__big_letter">{e}</div>
                        <div className="brands_by_letter">
                            {brands.filter(brand => brand.startsWith(e)).map((element,j)=>
                                <div
                                    className="brands__single_brand"
                                    key={`brand${j}`}
                                    data-search={element.toLowerCase().trim()}
                                >
                                    <button className="brands__single_brand__title" onClick={ToggleBrand}>{getHighlightedText(element,weSearch)}</button>
                                    <div className="brands__single_brand__info">
                                        <div className="brands__single_brand__info__text">{brandsDesc[element]}</div>
                                        <NavLink  to={`/all-products/?brand=${element}`} className="btn btn-info see_all_products_now" onClick={() => localStorage.setItem('brand_is_selscted', 'true')}>{t('niki.b2b.brands.seeall')}<Arrow /></NavLink>
                                        <div className="brands__single_brand__info__social">
                                            {brandsFacebook[element] && <a target="_blank" rel="nofollow noopener noreferrer" href={brandsFacebook[element]}><Facebook /></a>}
                                            {brandsLinkedin[element] && <a target="_blank" rel="nofollow noopener noreferrer" href={brandsLinkedin[element]}><Linkedin /></a>}
                                            {brandsInstagram[element] && <a target="_blank" rel="nofollow noopener noreferrer" href={brandsInstagram[element]}><Instagram /></a>}
                                            {brandsWebsite[element] && <a target="_blank" rel="nofollow noopener noreferrer" href={brandsWebsite[element]}><Home /></a>}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}</div>
                : <BrandsPlaceholder />
            }
            <div className="brands_search">
                <ul className="brands_search__abc" ref={abcEl}>{alphabet.map((e,i)=>
                    <li key={`brandsletter${i}`} data-length={letterLength[e]}>
                        <a href={`/brands/#${e}`} onClick={HideABCOverlay}>{e}</a>
                    </li>
                )}</ul>
                <div className="brands_search__abc__overlay" onClick={HideABCOverlay} ref={abcOverlayEl}></div>
                <div className="brands_search__form">
                    <input
                        type="search"
                        className="brands_search__form__input"
                        placeholder={t('niki.b2b.brands.searchfor')}
                        onChange={SearchBrand}
                        value={weSearch}
                    />
                    <button type="button" className="brands_search__form__abc" ref={abcButtonEl} onClick={ShowHideABC}>ABC</button>
                </div>
            </div>
        </div>
    )
}

const BrandsPlaceholder = () => {
    return (
        <div className="brands_animated__placeholder">
            <div className="content_placeholder content_placeholder__big_letter"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder content_placeholder__big_letter"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder content_placeholder__big_letter"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
            <div className="content_placeholder"></div>
        </div>
    )
}