import { FormatDate, ResetCart, FetchPreferencesPost, ShowLoader, HideLoader } from "../functions.js"
import { getRequestOptions, getBanners, getAccountBlocked } from '../helpers/apiHelper'

const compileAddressCompany = (address) => {
    return `${address.Address1}, ${address.PostCode}, ${address.country?.data?.name ? address.country.data.name : ''}`
}

const FetchPreferences = async () => {
    const url = `${process.env.REACT_APP_API_DOMAIN}/b2b/api-module-preferences?include=module,user&realKey=1&search=module_id:999`
    const response = await fetch(url, getRequestOptions())
    const json = await response.json()
    return json
}

const SetAccountData = (accountUserName, accountEntityID, accountEntityName, accountEntityIDReal, accountEntityCompany, accountEntityCompanyHashed, accountSegmentationIDs, currencyLeft, currencyRight, currencyCode, currencyDecimal, currencyThousand, localTaxNumber, warehouseID, warehouseIDReal, showStock, pricelineID, paymentID, paymentCondition, paymentName, estimatedDelivery, socialWebsite, socialFacebook, socialInstagram, socialYoutube, warehouseAddress, socials, userID, userIDReal, addressIDreal) => {
    if (localStorage.getItem('user.id') && localStorage.getItem('user.id') !== userID) ResetCart()
    localStorage.setItem('account.user.name', accountUserName)
    localStorage.setItem('account.entity.id', accountEntityID)
    localStorage.setItem('account.entity.id.real', accountEntityIDReal)
    localStorage.setItem('account.entity.name', accountEntityName)
    localStorage.setItem('account.company', accountEntityCompany)
    localStorage.setItem('account.company.hashed', accountEntityCompanyHashed)
    localStorage.setItem('account.segmentationids', accountSegmentationIDs)
    localStorage.setItem('currency.left', currencyLeft)
    localStorage.setItem('currency.right', currencyRight)
    localStorage.setItem('currency.code', currencyCode)
    localStorage.setItem('currency.decimal', currencyDecimal)
    localStorage.setItem('currency.thousand', currencyThousand)
    localStorage.setItem('local.tax.number', localTaxNumber)
    localStorage.setItem('warehouse.id', warehouseID ? warehouseID : 'J8RvMwowm7k2pNOr')
    localStorage.setItem('warehouse.id.real', warehouseIDReal)
    localStorage.setItem('show.stock', showStock)
    localStorage.setItem('priceline.id', pricelineID)
    localStorage.setItem('payment.id', paymentID)
    localStorage.setItem('payment.condition', paymentCondition)
    localStorage.setItem('payment.name', paymentName)
    localStorage.setItem('social.website', socialWebsite)
    localStorage.setItem('social.facebook', socialFacebook)
    localStorage.setItem('social.instagram', socialInstagram)
    localStorage.setItem('social.youtube', socialYoutube)
    localStorage.setItem('socials', socials)
    localStorage.setItem('user.id', userID)
    localStorage.setItem('user.id.real', userIDReal)
    let date = new Date()
    date.setDate(date.getDate() + parseInt(estimatedDelivery))
    localStorage.setItem('estimated.delivery', FormatDate(date))
    localStorage.setItem('warehouse.address', warehouseAddress)
    localStorage.setItem('address.id.real', addressIDreal)
}

export const applyAccount = (entityID) => {
    ShowLoader()
    fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/entity-account/${entityID}?include=company,address,address.contact,user,company.socialValues&realKey=1&type_id=8&itemSegmentations=true`, getRequestOptions())
        .then(response => response.json())
        .then(json => {
            if (json.data) {
                const account = json.data
                const accountSegmentationIDs = (account.itemSegmentationIds?.length > 0 && Array.from(new Set(account.itemSegmentationIds)).join(',')) || '';
                const segmentationIDs = accountSegmentationIDs.length > 1 ? accountSegmentationIDs.split(',') : [accountSegmentationIDs]
                getBanners(segmentationIDs.map(i => '&item_segmentations[]=' + i).join(''))
                    .then(json => {
                        if (json.data) {
                            localStorage.setItem('banners_data', JSON.stringify(json.data))
                        }
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        let socialFacebook = ''
                        let socialInstagram = ''
                        let socialWebsite = ''
                        let socialYoutube = ''
                        if (account.company?.data?.socialValues?.data > 0) {
                            account.company.data.socialValues.data.forEach(e => {
                                if (e.matchcode === 'Website') socialWebsite = e.value
                                if (e.matchcode === 'Facebook') socialFacebook = e.value
                                if (e.matchcode === 'Instagram') socialInstagram = e.value
                                if (e.matchcode === 'Youtube') socialYoutube = e.value
                            })
                        }
                        try {
                            const lastUrl = localStorage.getItem('current.url')
                            getAccountBlocked(account.id).then(json => {
                                if (json?.blocked === 'boolean' && (lastUrl !== 'debts' || lastUrl !== 'checkout')) {
                                    localStorage.setItem('isAccountBlocked', 'true')
                                }
                            })
                        } catch (error) {
                            console.error(error)
                        }
                        SetAccountData(
                            account.user.data.Name,
                            account.id,
                            account.matchcode,
                            entityID,
                            account.company?.data?.id,
                            account.company?.data?.hashed_id,
                            (account.itemSegmentationIds?.length > 0 && Array.from(new Set(account.itemSegmentationIds)).join(',')) || '',
                            account.currency?.left || '',
                            account.currency?.right || '',
                            account.currency?.code || '',
                            account.currency?.decimal_point || '',
                            account.currency?.thousand_point || '',
                            account.local_tax_number || '',
                            account.company?.data?.WarehouseID || 'J8RvMwowm7k2pNOr',
                            account.company?.data?.RealWarehouseID || 0,
                            account?.extrafieldValueable[0]?.value || 2,
                            account.source_price_id || account.company?.data?.default_priceline_id || '',
                            account.payment_type_id_real || 0,
                            account.payment_condition_matchcode || '',
                            account.payment_type_name || '',
                            account.estimated_delivery || 1,
                            socialWebsite,
                            socialFacebook,
                            socialInstagram,
                            socialYoutube,
                            account.company?.data?.Address1 && account.company?.data?.PostCode ? compileAddressCompany(account.company.data) : '',
                            JSON.stringify(account.company?.data?.socialValues?.data),
                            account.user?.data?.id || '',
                            account.user?.data?.UserID || 0,
                            account.address_id_real || 0
                        )
                        localStorage.setItem('username', account.user?.data?.username)
                        localStorage.setItem('entity_account.priceline_id', account.priceline_id)
                        localStorage.setItem('payment_condition_matchcode_translations', JSON.stringify(account.payment_condition_matchcode_translations))
                        localStorage.setItem('payment_type_name_translations', JSON.stringify(account.payment_type_name_translations))
                        localStorage.setItem('marketingCampaignIds',
                            JSON.stringify(json.data.marketingCampaignIds)
                                ? JSON.stringify(json.data.marketingCampaignIds)
                                : '{"new":[],"promo":[],"booklet":[]}'
                        )

                        FetchPreferences()
                            .then(json => {
                                const url = localStorage.getItem('current.url') || '/'

                                if (json.data) {
                                    !localStorage.getItem('locale') && localStorage.setItem('locale', JSON.parse(json.data[0]?.preferences)['language'])
                                    localStorage.setItem('enter.shop', '1')
                                    window.location.href = url
                                } else {
                                    FetchPreferencesPost()
                                        .then(json => {
                                            if (json.data) {
                                                localStorage.setItem('enter.shop', '1')
                                                window.location.href = url
                                            }
                                        })
                                }
                                localStorage.removeItem('entity_accounts');
                                localStorage.removeItem('user_name');
                            })
                    })
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
}

export const maskEmail = email => {
    let maskedEmail = ''
    let emailParts = email.split('@')
    let username = emailParts[0]
    let domain = emailParts[1]
    var maskedUsername = ''
    var usernameLength = username.length
    if (usernameLength > 4) {
        var maskLength = Math.floor(usernameLength / 2)
        maskedUsername += username.substring(0, usernameLength - maskLength).replace(/./g, '*')
        maskedUsername += username.substring(usernameLength - maskLength, usernameLength)
    } else {
        maskedUsername = username.replace(/./g, '*')
    }
    maskedEmail = `${maskedUsername}@${domain}`
    return maskedEmail
}

export const maskPhone = phoneNumber => {
    const masked = phoneNumber.slice(0, -2).replace(/\d/g, '*')
    const visible = phoneNumber.slice(-2)
    return `${masked}${visible}`
}