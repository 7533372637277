// import React, { useEffect, useState, useCallback } from "react"
// import { useParams, NavLink } from "react-router-dom"
// import { t } from "i18next"
// import { priceLineID } from './../config.js'
// import { PriceFormat, GetMainImageCart, GetHistoryData, ShowLoader, HideLoader, returnStockStatusText, getTranslatedName } from "./../functions.js"
// import { getRequestOptions, includeForProduct } from './../helpers/apiHelper'

// import { BreadCrumbs } from "./../components/BreadCrumbs"
// import { OrderHistoryPopup } from "../components/OrderHistoryPopup.jsx"

// import './../assets/css/shoppingcart.css'

// import { ReactComponent as History } from './../assets/svg/schistory.svg'

// const GetOldPrice = itemDiscounts => {
//     let oldPrice = 0
//     if (itemDiscounts && itemDiscounts[0]) oldPrice = itemDiscounts[0].tax_included_price
//     return oldPrice
// }

// const ReturnStock = (StockCount,UnitCode) => {
//     let { stockStatus, stockText } = returnStockStatusText(StockCount,UnitCode)
//     return <div className={stockStatus}><span className={[`shopping_cart__stock`,stockStatus].join(' ')}></span>{stockText}</div>
// }

export const ShoppingCart = () => {
    // const { id } = useParams()
    // const uuid = id

    // const [orderIDReal,setOrderIDReal] = useState(0)
    // const [createdAt,setCreatedAt] = useState('')
    // const [cartContent, setCartContent] = useState([])
    // const [isOpenHistoryProduct, setIsOpenHistoryProduct] = useState(false)
    // const [idProductForHistory, setIdProductForHistory] = useState('')
    
    // /* scroll direction */
    // const [y,setY] = useState(window.scrollY)
    // const handleNavigation = useCallback(e => {
    //     const window = e.currentTarget
    //     y > window.scrollY ? document.body.classList.add('fixed_cart_summary') : document.body.classList.remove('fixed_cart_summary')
    //     setY(window.scrollY)
    // },[y])

    // useEffect(() => {
    //     setY(window.scrollY)
    //     window.addEventListener("scroll", handleNavigation)
    //     return () => {
    //         window.removeEventListener("scroll", handleNavigation)
    //     }
    // }, [handleNavigation])
    // /* scroll direction */

    // useEffect(() => {
    //     ShowLoader()
    //     fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-header/${uuid}/${localStorage.getItem('account.entity.id')}?realKey=1&orderBy=id&sortedBy=desc`,getRequestOptions())
    //     .then(response => response.json())
    //     .then(json => {
    //         json.data.id_real && setOrderIDReal(json.data.id_real)
    //         json.data.readable_created_at && setCreatedAt(json.data.readable_created_at)
    //     })
    //     .catch(error => console.error(error))
    //     .finally(() => HideLoader())
    // },[uuid])

    // const [cartSKUs,setCartSKUs] = useState(0)
    // const [cartSumm,setCartSumm] = useState(0)
    // const [cartVat, setCartVat] = useState(0)
    
    // useEffect(()=>{
    //     fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details?cart_header_uuid=${uuid}&include=${includeForProduct}&realKey=true&showVat=true&warehouse_id=${localStorage.getItem('warehouse.id')}&source_price_id[]=${priceLineID}&limit=10000`,getRequestOptions())
    //     .then(response => response.json())
    //     .then(json => {
    //         if (json.data) {
    //             setCartContent([])
    //             let skusnumber = 0
    //             let carttotalsumm = 0
    //             let carttotalvat = 0
    //             json.data && json.data.forEach(e => {
    //                 const prodQty = (e.qty && parseFloat(e.qty)) || 0
    //                 let price = parseFloat(e.item?.data?.itemPrices?.data[0]?.unit_price) || 0
    //                 let prodGross = parseFloat(e.item?.data?.itemPrices?.data[0]?.tax_included_price) || 0
    //                 let priceOld = 0
    //                 if (e.item?.data?.itemDiscounts?.data[0]?.unit_price && e.item?.data?.itemDiscounts?.data[0]?.tax_included_price) {
    //                     priceOld = price
    //                     price = parseFloat(e.item.data.itemDiscounts.data[0].unit_price)
    //                     prodGross = parseFloat(e.item.data.itemDiscounts.data[0].tax_included_price)
    //                 }
    //                 const prodVat = prodGross - price
    //                 const tempObj = {
    //                     name: getTranslatedName(e.item.data) ? getTranslatedName(e.item.data) : e.matchcode,
    //                     id: e.id,
    //                     itemid: e.item?.data?.item_id,
    //                     qty: prodQty,
    //                     qtyinbox: e.qty_in_box,
    //                     price: price,
    //                     priceOld: priceOld,
    //                     gross: prodGross,
    //                     price_discount: (e.item.data && e.item.data.itemDiscounts && e.item.data.itemDiscounts.data && GetOldPrice(e.item.data.itemDiscounts.data)) || e.unit_price,
    //                     productid: e.item.data.id,
    //                     unitcode: e.UNI || e.item.data.itemStocks.data[0].stock_unit_code,
    //                     image: GetMainImageCart(e.item.data.attachments,e.item.data.attachment_id),
    //                     instock: e.item.data.itemStocks.data[0].available,
    //                     vat: prodVat,
    //                     group: (e.item.data.itemCategories && e.item.data.itemCategories.data && e.item.data.itemCategories.data[0] && e.item.data.itemCategories.data[0].name) || '',
    //                     type: (e.item.data.itemKinds && e.item.data.itemKinds.data && e.item.data.itemKinds.data[0] && e.item.data.itemKinds.data[0].name) || '',
    //                     qtyInBox: e.quantity,
    //                 }
    //                 setCartContent(prevState => ([...prevState, tempObj]))
    //                 skusnumber++
    //                 carttotalsumm += parseFloat(e.unit_price)*parseInt(e.qty)
    //                 carttotalvat += prodVat
    //             })
    //             setCartSKUs(skusnumber)
    //             setCartSumm(carttotalsumm)
    //             setCartVat(carttotalvat)
    //         }
    //     })
    // },[uuid])

    // const ExplandCollapseCartDetail = e => {
    //     const productID = e.target.dataset.id
    //     document.querySelector(`.shopping_cart__list__item.${productID}`).classList.toggle('active')
    // }

    // const ShowProdHistory = e => {
    //     setIsOpenHistoryProduct(true)
    //     setIdProductForHistory(e.target.dataset.id)
    // }

    // const onProductClick = () => {
    //     localStorage.setItem('url.back', `${window.location.pathname}`)
    // }

    return (
        <div className="content">
            {/* <BreadCrumbs itemlist={[{'url':'/account/','title':t('niki.b2b.menu.myaccount')},{'url':'/shoppingcarts/','title':t('niki.b2b.cart.shoppingcarts')},{'url':`/shoppingcart/${uuid}/`,'title':`${t('niki.b2b.cart.shoppingcart')} ${orderIDReal}`}]} />
            <div className="shopping_cart__content">
                <h1>{t('niki.b2b.cart.shoppingcart')} {orderIDReal}</h1>
                <div className="shopping_cart__created">{t('niki.b2b.cart.created')}: <span>{createdAt}</span></div>
                {cartContent.length === 0
                    ? <CartPreloader number="10" />
                    : <div className="shopping_cart__list">{cartContent.map((e,i)=>
                        <div key={`cartcontentlistitem${i}`} className={[`shopping_cart__list__item`,`id${e.productid.toLowerCase()}`].join(' ')}>
                            <div className="shopping_cart__list__item__first">
                                <NavLink to={`/productpage/${e.productid}/`} onClick={onProductClick} className="shopping_cart__list__item__first__image">
                                    <img src={e.image} alt={e.name} loading="lazy" />
                                </NavLink>
                                <div>
                                    <NavLink to={`/productpage/${e.productid}/`} onClick={onProductClick} className="shopping_cart__list__item__first__product_name">{e.name}</NavLink>
                                    <div className="shopping_cart__history_price_expland">
                                        <div>{GetHistoryData(e.productid).length > 0 &&
                                            <button type="button" onClick={ShowProdHistory} data-id={e.productid}><History /></button>
                                        }</div>
                                        <div>
                                        {e.priceOld > 0 && <span className="shopping_cart__discount">-{Math.round((e.priceOld-e.price)/e.priceOld*100)}%</span>}
                                        </div>
                                        <div className="shopping_cart__total">{PriceFormat(parseInt(e.qty)*parseFloat(e.price))}</div>
                                        <div className="shopping_cart__incart">{`${e.qty}`.length > 4 ? (`${e.qty}`.slice(0,`${e.qty}`.length-3)+"K+") : e.qty}</div>
                                        <div><button className="shopping_cart__expland" data-id={`id${e.productid.toLowerCase()}`} onClick={ExplandCollapseCartDetail}></button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="shopping_cart__list__item__second">
                                <div>
                                    <ul className="shopping_cart__list__item__second__list">
                                        {e.group && <li>{t('niki.b2b.shoppingcart.group')}: {e.group}</li>}
                                        {e.type && <li>{t('niki.b2b.feedback.type')}: {e.type}</li>}
                                        <li>{e.unitcode}: {t('niki.b2b.cartflow.box')}: {e.qtyinbox}</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul className="shopping_cart__list__item__second__list">
                                        <li>{t('niki.b2b.product.unitprice')}: {PriceFormat(e.price)}</li>
                                        {e.vat > 0 && <li>{t('niki.b2b.product.vat')}: {Math.round(e.vat/e.price*100)}%</li>}
                                        <li>{ReturnStock(e.instock,e.unitcode)}</li>
                                    </ul>
                                </div>
                                <div className="shopping_cart__list__item__second__last_column">
                                    <button className="shopping_cart__num_button">{e.qty}</button>
                                </div>
                            </div>
                            {GetHistoryData(e.productid).length > 0 && isOpenHistoryProduct && idProductForHistory === e.productid &&
                                <OrderHistoryPopup
                                    matchcode={e.name}
                                    imgsrc={e.image}
                                    sale_unit_code={e.unitcode}
                                    qty_in_box={e.qtyinbox}
                                    item_id={e.itemid}    
                                    product_id={e.productid}
                                    setIsOpenHistoryProduct={setIsOpenHistoryProduct}
                                />
                            }
                        </div>
                    )}</div>
                }
            </div>
            <div className="shopping_cart__summary">
                <div className="shopping_cart__summary__white">
                    <div className="shopping_cart__summary__white__first">
                        <div>{t('niki.b2b.cartflow.totalskus')}</div>
                        <div>{t('niki.b2b.orders.totalnet')}</div>
                        <div>{t('niki.b2b.orders.totalvat')}</div>
                        <div className="red">{t('niki.b2b.cartflow.savings')}</div>
                    </div>
                    <div className="shopping_cart__summary__white__second">
                        <div>{cartSKUs}</div>
                        <div>{PriceFormat(cartSumm)}</div>
                        <div>{PriceFormat(cartVat)}</div>
                        <div className="red">{PriceFormat(0)}</div>
                    </div>
                    <div className="shopping_cart__summary__white__third">
                        <div>
                            <div className="shopping_cart__cart_total">{t('niki.b2b.cart.carttotal')}</div>
                            <div className="shopping_cart__cart_summary_price">{PriceFormat(cartSumm)}</div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>       
    )
}

// const CartPreloader = props => {
//     const arrayTemp = [...Array(parseInt(props.number))]
//     return (
//         <>{arrayTemp.map((e,i)=><div key={`cartplaceholder${i}`} className="cart__content_placeholder content_placeholder"></div>)}</>
//     )
// }