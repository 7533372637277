import React, { useEffect, useState, useRef } from "react"
import { t } from "i18next"
import { useNavigate } from 'react-router-dom'

import { getEntityData } from '../helpers/apiHelper'
import { ResetCart, ShowLoader, HideLoader } from "./../functions.js"
import { applyAccount } from '../login/loginFunctions'
import { ReactComponent as LoginSubmit } from './../assets/svg/loginpagesubmit.svg'

import "../assets/css/login.css"

export const ChangeEntityAccount = ({ closeModal }) => {

    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [selectedEntity, setSelectedEntity] = useState({})
    const [initialData, setInitialData] = useState([])
    const [accountName, setAccountName] = useState('')
    const [disableButton, setDisableButton] = useState(false)

    const inputRef = useRef(null);

    useEffect(()=>{
        ShowLoader()
        getEntityData().then(json => { 
            if (json.data) {
                if (json.data?.length) {
                    const accounts = json.data
                    const accountData = []
                    let selectedAccount = ''
                    let Id = 0
                    accounts.forEach(account => {
                        if (account.matchcode === localStorage.getItem('account.entity.name')) {
                            selectedAccount = account.external_id + ' - ' + account.matchcode
                            Id = account.id
                        } 
                        accountData.push({ ...account, accountName: account.external_id + ' - ' + account.matchcode })
                    })
                    setData(accountData)
                    setInitialData(accountData)
                    if (accountData.length) {
                        setSelectedEntity({
                            id: Id ? Id : accountData[0].id,
                            accountName: selectedAccount ? selectedAccount : accountData[0].accountName,
                        })
                        setAccountName(selectedAccount ? selectedAccount : accountData[0].accountName)
                    }
                }
            }
        })
        HideLoader()
    }, [])
    
    useEffect(() => {
        let timer;
        clearTimeout(timer);
        if (disableButton) {
            timer = setTimeout(() => {
                setDisableButton(false)
            }, 4000)
        }
        return () => clearTimeout(timer)
    }, [disableButton])

    const onSelectEntity = e => {
        const elementDataset = e.target.dataset
        setSelectedEntity({
            id: elementDataset.id,
            accountName: elementDataset.accountname,
        })
        setAccountName(elementDataset.accountname)
        setData(initialData)
        document.querySelector('.login__select_account').classList.remove('active')
    }
    
    const onInputClick = () => {
        document.querySelector('.login__select_account').classList.add('active')
        const currentElement = document.querySelector('li.current');
       
        if (currentElement) {
            currentElement.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            })
        }
    }

    const onChangeInput = (event) => {
        setAccountName(event.target.value)
        if (!event.target.value) {
            setData(initialData)
            return
        } else {
            const accountsFilter = initialData.filter(ac => ac.accountName.toLowerCase().includes(event.target.value.toLowerCase()))
            setData(accountsFilter)
        }
    }

    const handleClearClick = () => {
        setAccountName('');
        onInputClick();
        setData(initialData);
        inputRef.current.focus();
    };

    const enterShop = () => {
        setDisableButton(true)
        ResetCart()
        localStorage.removeItem('favorites')
        localStorage.removeItem('history.ids')
        applyAccount(selectedEntity.id)
        navigate('/all-products/')
    }

    return (
        <div className='change_account__wrapper'>
            <div className="change_account__popup">
                <div className="change_account__wrapper_content">
                    <div className="change_account__content">
                        <div className="change_account__content_title">
                            <div className="login__header__title">{t('niki.b2b.login.selectaccount')}</div>                        
                            <span
                                className="clear-button"
                                onClick={closeModal}
                                style={{ fontSize: '30px', cursor: 'pointer'}}
                            >
                                ×
                            </span>
                        </div>
                        <div className="login__select_account">
                            <input className="login__select_account__current"
                                type="text"
                                value={accountName}
                                placeholder={t('niki.b2b.login.selectaccount')}
                                onClick={onInputClick}
                                onChange={(e) => onChangeInput(e)}
                                ref={inputRef}
                            />
                            <span
                                className="clear-button"
                                onClick={handleClearClick}
                                style={{ display: accountName ? 'inline-block' : 'none' }}
                            >
                                ×
                            </span>
                            <ul className="login__select_account__list">
                            {data.map((e,i)=>
                                <li
                                    key={`entityaccounts${i}`} onClick={onSelectEntity}                
                                    data-id={e.id}
                                    data-accountname={e.accountName}
                                    className={e.accountName === accountName ? 'current' : null}
                                >{e.accountName}</li>
                            )}
                            </ul>
                        </div>
                        <div className="select_account__submit">
                            <button className="btn" type="button" disabled={disableButton || `${selectedEntity.id}` === localStorage.getItem('account.entity.id.real')} onClick={enterShop}>
                                {t('niki.b2b.login.enter')}<LoginSubmit />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="loader"></div>
            </div>
        </div>
    )
}