import React, { useEffect, useState } from "react"
import { NavLink } from 'react-router-dom'
import { t } from "i18next"

import { catalogsTypeID } from './../config.js'
import { getRequestOptions } from '../helpers/apiHelper'
import { ShowLoader, HideLoader } from "../functions"

import { BreadCrumbs } from "../components/BreadCrumbs"
import LoadableImage from "../components/LoadableImage/LoadableImage"

import { ReactComponent as Download } from './../assets/svg/productcatalogdownload.svg'

const FormatDate = localDate => {
    const fullDate = new Date(localDate)
    return `${String(fullDate.getDate()).padStart(2, '0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}

export const ProductCatalog = () => {

    const [catalogsData,setCatalogsData] = useState([])
    const [currentCatalog, setCurrentCatalog] = useState({})
    const [isDowandImage,setIsDowandImage] = useState(true)
    useEffect(()=>{
        ShowLoader()
        // fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/catalogs?include=creator,modifier,status,group,company,tasks,attachment`,requestOptions)
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/marketing-campaigns?include=attachments&search=typeableType.id:${catalogsTypeID}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            setCatalogsData([])
            let currentAssigned = false
            json.data && json.data.forEach((e,i) => {
                let pdfPath = ''
                let thumbPath = ''
                if (e.attachments && e.attachments.data && e.attachments.data.length > 0) {
                    e.attachments.data.reverse().forEach(el => {
                        if (el.extension === 'pdf') {
                            pdfPath = el?.links[0]?.url || ''
                        } else {
                            thumbPath = el?.links[0]?.url || ''
                        } 
                    })
                }
                const tempObj = {
                    id: e.id,
                    title: e.matchcode,
                    edition: e.matchcode,
                    from: e.start_at,
                    to: e.finish_at,
                    season: e.group && e.group.data && e.group.data.name,
                    link: pdfPath,
                    image: thumbPath,
                }
                if (thumbPath.length >0 && pdfPath.length > 0) {
                    setCatalogsData(prevState => ([...prevState, tempObj]))
                    if (!currentAssigned) {
                        setCurrentCatalog(tempObj)
                        currentAssigned = true
                    }
                }
            })
        })
        .catch(error => console.error(error))
        .finally(() => {
            HideLoader()
            setIsDowandImage(false)
        })
    },[])
    return (
        <div className="content">
            <BreadCrumbs itemlist={[{'url':'/marketing/','title':t('niki.b2b.menu.marketing')},{'url':'/product-catalogs/','title':t('niki.b2b.productcatalogs.h1')}]} />
            <div className="product_catalog__content">
                <h1>{t('niki.b2b.productcatalogs.h1')}</h1>

                <div className="product_catalog__single_big">
                    {currentCatalog.image || isDowandImage
                        ? <LoadableImage src={currentCatalog.image} alt={t('niki.b2b.productcatalogs.h1')} height={'300px'}/>
                         // <img src={currentCatalog.image} alt={t('niki.b2b.productcatalogs.h1')} />
                        : <img src="" alt=""/>
                    }
                    <div>{t('niki.b2b.productcatalogs.h1')}</div>
                    {currentCatalog.from
                        ? <div className="product_catalog__single_big__dates">{FormatDate(currentCatalog.from)} - {FormatDate(currentCatalog.to)}</div>
                        : <div className="product_catalog__single_big__dates">&nbsp;</div>
                    }
                </div>

                <h2 className="marginbottom20">{t('niki.b2b.productcatalogs.full')}</h2>
                <div className="marginbottom20">{t('niki.b2b.productcatalogs.downloadfull')}.</div>
                <div className="marginbottom20">
                    <a className="btn btn-info" target="_blank" rel="noopener noreferrer nofollow" href={currentCatalog.link}>{t('niki.b2b.productcatalogs.downloadcurrent')}</a>
                </div>
                <div className="marginbottom40"><NavLink to="/promotional-booklets/" className="btn">{t('niki.b2b.productcatalogs.viewbooklets')}</NavLink></div>

                <h2 className="center marginbottom40">{t('niki.b2b.productcatalogs.full')}</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>{t('niki.b2b.productcatalogs.edition')}</th>
                            {/* <th>{t('niki.b2b.productcatalogs.season')}</th> */}
                            <th>{t('niki.b2b.product.date')}</th>
                            <th>{t('niki.b2b.process.download')}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {catalogsData.map((e,i)=>
                        <tr key={`productcatalog${i}`}>
                            <td><span className="nowrap">{e.edition}</span></td>
                            {/* <td><span className="nowrap">{e.season}</span></td> */}
                            <td>{`${FormatDate(e.from)}-${FormatDate(e.to)}`}</td>
                            <td className="product_catalogs_colum__save">{e.link && <a href={e.link} target="_blank" rel="noopener noreferrer nofollow"><Download /></a>}</td>
                        </tr>
                    )}
                    </tbody>
                </table>

            </div>
        </div>
    )
}