import React, { useRef, useEffect, useState }  from 'react'
import { useDispatch, useSelector } from "react-redux"
import { t } from "i18next"
import useSound from 'use-sound'

import { priceLineID } from '../../config.js'
import { ShowLoader, HideLoader, LangFix, getCartProducts, setCartIdsToStorage } from '../../functions.js'
import { getRequestOptions, getProductFormSearch, includeForProduct, returnErrorDuringAddProductToCart } from '../../helpers/apiHelper'
import { addCartProduct, changeQuickAddProductCount } from "../../reduxToolkit/cartSlice"

import { QuickAddProductTemplate } from './QuickAddProductTemplate'
import Scanner from "../Scanner"
import '../../assets/css/shoppingcart.css'
import scannerSound from '../../assets/barcodescanner.mp3'

import defaultImage from '../../assets/images/placeholder.jpg'

let cartdata = {}
const selectedLanguage = LangFix(localStorage.getItem('locale'))

export const AddProductToCart = ({setLastElement, setOpenAddProduct}) => {

    const dispatch = useDispatch()

    const quickAddProductCount = useSelector(state => state.cartContent.quickAddProductCount)
    const productsInCart = useSelector(state => state.cartContent.productIDs)

    const quickAddProductInputEl = useRef(null)
    const quickAddProductEl = useRef(null)
    const sumbitSearchFormEl = useRef(null)
    const submitSearchButtonel = useRef(null)

    const [search,setSearch] = useState('')
    const [searchData,setSearchData] = useState({})
    const [searchResultsStatus, setSearchResultsStatus] = useState('waiting')
    const [toCartData, setToCartData] = useState({})
    const [isCloseSearchStatus, setIsCloseSearchStatus] = useState(false)

    useEffect(() => {
        quickAddProductEl.current.classList.add('active')
        quickAddProductInputEl.current.focus()
    }, [])

    useEffect(() => {
        if (search.length > 0 && searchResultsStatus === 'empty') {
            setSearchResultsStatus('waiting')
        }
    }, [search, searchResultsStatus])

    const AddToCart = () => {
        if (search.trim().length === 0) {
            setSearchResultsStatus('empty')
        } else {
            if (localStorage.getItem('cart.uuid')) {
                const uuid = localStorage.getItem('cart.uuid')
                ShowLoader()
                const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
                requestOptionsCart.body = JSON.stringify({
                    cart_header_uuid: uuid,
                    item_id: toCartData.productID,
                    matchcode: toCartData.productName,
                    variation_id: '3',
                    qty_in_box: toCartData.productQTYINBOX.toString(),
                    unit_price: toCartData.productPrice.toString(),
                    tax_included_price: toCartData.productPriceTax.toString(),
                    qty: quickAddProductCount,
                })
                
                fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details?include=${includeForProduct}`,requestOptionsCart)
                .then(response => response.json())
                .then(json => {
                    if (json?.message) {
                        returnErrorDuringAddProductToCart(json.message, dispatch)
                    } else if (json.data) {
                        const cartData = getCartProducts(json.data)
                        dispatch(addCartProduct(cartData))
                        setLastElement(true)
                    }
                })
                .catch(error => returnErrorDuringAddProductToCart(error, dispatch))
                .finally(() => {
                    HideLoader()
                    HideQuickAddProduct()
                })
            } else {
                let detaildata = []
                detaildata[0] = {
                    matchcode: toCartData.productName,
                    variation_id: '1',
                    qty: toCartData.productQTYINBOX.toString(),
                    unit_price: toCartData.productPrice.toString(),
                    tax_included_price: toCartData.productPriceTax.toString(),
                    qty_in_box: toCartData.productQTYINBOX.toString(),
                    item_id: toCartData.productID,
                }
                const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
                requestOptionsCart.body = JSON.stringify({
                    company_id: localStorage.getItem('account.company.hashed'),
                    customer_account_id: localStorage.getItem('account.entity.id'),
                    status_id: 'J8RvMwowm7k2pNOr',
                    details: detaildata
                })
              
                ShowLoader()
                fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers`,requestOptionsCart)
                .then(response => response.json())
                .then(json => {
                    if (json.data) {
                        setCartIdsToStorage(json.data.uuid, json.data.id_real, json.data.id)
                        cartdata = {}
                        cartdata.id = json.data.id
                        cartdata.name = toCartData.productName
                        cartdata.qty = quickAddProductCount
                        cartdata.qtyinbox = toCartData.productQTYINBOX
                        cartdata.unitcode = toCartData.productUni
                        cartdata.price = toCartData.productPrice
                        cartdata.priceOld = toCartData.productPriceOld
                        cartdata.productid = toCartData.productID
                        cartdata.itemid = toCartData.productItemID
                        cartdata.image = toCartData.productImage
                        cartdata.instock = toCartData.instock
                        cartdata.productitemid = json.data.product_item_id
                        dispatch(addCartProduct(cartdata))
                        HideQuickAddProduct()
                    }
                })
                .catch(error => console.error(error))
                .finally(() => HideLoader())
            }
        }
    }
    
    const closeSearchStatus = () => setIsCloseSearchStatus(true)

    useEffect(() => {
        let timer;
        let timer1;

        if (isCloseSearchStatus) {
            clearTimeout(timer);
            clearTimeout(timer1);
            timer = setTimeout(() => {
                setSearchResultsStatus('waiting')
                timer1 = setTimeout(() => {
                    quickAddProductInputEl.current.focus()
                    setIsCloseSearchStatus(false)
                }, 200)
            }, 2500)
        }
        return () => {
            clearTimeout(timer)
            clearTimeout(timer1)
        };
        // eslint-disable-next-line
    }, [isCloseSearchStatus])
    
    const GetSearchData = () => {
        if (search.trim().length === 0) {
            setSearchResultsStatus('empty')
        } else {
            ShowLoader()
            getProductFormSearch(search)
                .then(json => {
                    if (json.hits?.length > 0) {
                        const res = json.hits[0]
                        setSearchResultsStatus('good')
                        if (productsInCart.includes(res.id)) {
                            setSearchResultsStatus('isincart')
                            closeSearchStatus()
                        } else {
                            const itemPrices = res?.itemPrices[priceLineID]
                            const discounts = res.is_discount && res?.itemDiscounts[priceLineID]
                            const unitPrice = parseFloat(itemPrices.unit_price) || 0
                            const taxIncludedPrice = parseFloat(itemPrices.tax_included_price) || 0
                            const productName = res?.itemDescriptions[selectedLanguage]?.short_description ? res.itemDescriptions[selectedLanguage].short_description : res.matchcode
                            const productPrice = res.is_discount ? parseFloat(discounts.tax_included_price) : unitPrice
                            const productPriceOld = unitPrice
                            const productTax = res.is_discount ? parseFloat(discounts.tax_included_price) : taxIncludedPrice
                            const productQtyInbox = parseInt(res?.itemUnitBarcodeByUnitCodeBox?.coefficient) || 0
                            const productImage = res.attachment || defaultImage
                            const productUni =  res.main_unit_code_translations ? res.main_unit_code_translations[selectedLanguage] : res.sale_unit_code || ''
                            const instock = res.itemStocks.length > 0 ? res.itemStocks[0]?.available : 0
                            
                            setSearchData({ ...res, productName: productName, instock: instock, productImage: productImage })
                            setToCartData({
                                productID: res.id,
                                productName: productName,
                                productQTYINBOX: productQtyInbox,
                                productPrice: productPrice,
                                productPriceOld: productPriceOld,
                                productPriceTax: productTax,
                                productUni: productUni,
                                productItemID: res.item_id,
                                productImage: productImage,
                                instock: instock
                            })
                            dispatch(changeQuickAddProductCount(productQtyInbox))
                            cartdata = {}
                        }
                    } else {
                        setSearchResultsStatus('nomatch')
                        closeSearchStatus()
                    }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }

    const SearchForm = e => {
        e.preventDefault()
        GetSearchData()
    }

    const HideQuickAddProduct = () => {
        setOpenAddProduct(false)
        quickAddProductEl.current.classList.remove('active')
        setSearchResultsStatus('waiting')
        setSearch('')
    }

    const blackOverlayEl = useRef(null)
    const [playScannerSound] = useSound(scannerSound)
    const [camera, setCamera] = useState(false)
    const [cameraResult, setCameraResult] = useState(null)
    const [isScanner, setIsScanner] = useState(false)

    let scannerDataGets = false
    const onDetected = result => {
        if (result !== null && scannerDataGets === false) {
            HideBarcode()
            setSearch(result)
            setCamera(false)
            setCameraResult(result)
            scannerDataGets = true
            playScannerSound()
            setIsScanner(true)
            // GetSearchData()
        }
    }

    useEffect(() => {
        let timer;

        if (isScanner) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                submitSearchButtonel.current.click()
                setIsScanner(false)
            }, 500)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isScanner])

    const ShowBarcode = () => {
        setCamera(true)
        setCameraResult(null)
        blackOverlayEl.current.classList.add('active')
    }
    const HideBarcode = () => {
        setCamera(false)
        setCameraResult(null)
        blackOverlayEl.current.classList.remove('active')
    }

    return (
        <>
            <div className="shopping_cart__add_product__wrapper" ref={quickAddProductEl}>
                <div className="shopping_cart__add_product">
                    <div className="shopping_cart__add_product__white">
                        <h2>{t('niki.b2b.cartflow.addnewproduct')}</h2>
                        {(searchResultsStatus === 'waiting' || searchResultsStatus === 'empty') &&
                            <form className="shopping_cart__add_product__white__form" onSubmit={SearchForm} ref={sumbitSearchFormEl}>
                                <input
                                    type="search"
                                    className="form-control"
                                    placeholder="Product SKU or Barcode"
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                    ref={quickAddProductInputEl}
                                />
                                <button className="shopping_cart__add_product__white__search" type="submit" ref={submitSearchButtonel}></button>
                                <button className="shopping_cart__add_product__white__barcode" type="button" onClick={ShowBarcode}></button>
                            </form>
                        }
                        {/* <div>search: &laquo;{search}&raquo;</div> */}
                        {searchResultsStatus === 'nomatch' && <div className="alert alert-danger">{t('niki.b2b.cartflow.errorfindproduct')}</div>}
                        {searchResultsStatus === 'empty' && <div className="alert alert-danger">{t('niki.b2b.cartflow.emptyinput')}</div>}
                        {searchResultsStatus === 'isincart' && <div className="alert alert-danger">{t('niki.b2b.cartflow.isincart')}</div>}
                        {searchResultsStatus === 'good' && <QuickAddProductTemplate data={searchData} />}
                    </div>
                    <div className={[`shopping_cart__add_product__grey`,searchResultsStatus].join(' ')}>
                        <button className="btn" onClick={HideQuickAddProduct}>{t('niki.b2b.cartflow.cancel')}</button>
                        <button className="btn btn-primary" onClick={AddToCart}>{t('niki.b2b.cartflow.addtocart')}</button>
                    </div>
                </div>
                <div className="shopping_cart__add_product__overlay" onClick={HideQuickAddProduct}></div>
            </div>
            <div className="barcode_overlay" ref={blackOverlayEl}>
                <div className="BarcodeScanner">
                    <div className="camera_container">
                        {camera && <Scanner onDetected={onDetected} />}
                    </div>
                </div>
                <div className="barcode_overlay__info">
                    <strong>{t('niki.b2b.cartflow.scanean')}</strong>
                    <span>{cameraResult ? cameraResult : "No EAN Detected"}</span>
                    <button className="btn btn-primary" type="button" onClick={HideBarcode}>{t('niki.b2b.cartflow.cancel')}</button>
                </div>
            </div>
        </>
    )
}