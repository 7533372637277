import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux" 
import { t } from "i18next"

import { ShowLoader, HideLoader, setCartIdsToStorage } from '../../functions.js'
import { getRequestOptions } from '../../helpers/apiHelper'
import { setCartHeaders, clearCartContent, clearCartIdName, setUuidCart } from "../../reduxToolkit/cartSlice"
import { setErrorMessage } from '../../reduxToolkit/allproduct'

import '../../assets/css/shoppingcart.css'

export const RenameCart = ({ orderID, orderIDReal, closeRenamePopup, uuid = '', isCreateCart = false, isCreateCartLocal = false}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const cartRenameInputEl = useRef(null)

    const [newName, setNewName] = useState('')
    const [isNeedEnterNewName, setIsNeedEnterNewName] = useState(false)

    useEffect(() => {
        if (newName && isNeedEnterNewName) {
            setIsNeedEnterNewName(false)
        }
    }, [newName, isNeedEnterNewName])

    const onClosealert = () => {
        setIsNeedEnterNewName(false)
        setNewName('')
        closeRenamePopup()
    }

    const SubmitNewName = e => {
        e.preventDefault()
        if (isCreateCartLocal) {
            const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
            requestOptionsCart.body = JSON.stringify({
                company_id: localStorage.getItem('account.company.hashed'),
                customer_account_id: localStorage.getItem('account.entity.id'),
                status_id: 'J8RvMwowm7k2pNOr',
                realKey: '1',
                matchcode: newName,
            })
            
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers`,requestOptionsCart)
            .then(response => response.json())
            .then(json => {
                json?.message && dispatch(setErrorMessage(json?.message))
                if (json.data) {
                    dispatch(clearCartContent())
                    dispatch(clearCartIdName())
                    dispatch(setUuidCart(json.data.uuid))
                    setCartIdsToStorage(json.data.uuid, json.data.id_real, json.data.id)
                    navigate('/myshoppingcart/')
                }
            })
            .catch(error => console.error(error))
            .finally(() => {
                HideLoader()
                onClosealert()
            })
    
            return
        }
        if (isCreateCart) {
            closeRenamePopup(newName)
            return
        }
        if (!newName) {
            cartRenameInputEl.current.focus()
            setIsNeedEnterNewName(true)
            return
        }
        const requestOptionsPatchMatchcode = getRequestOptions('PATCH', null, 'application/json')
        requestOptionsPatchMatchcode.body = JSON.stringify({ matchcode: newName})
       
        try {
            ShowLoader()
            const cartId = orderID ? orderID : localStorage.getItem('cart.id')
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-header/${cartId}`,requestOptionsPatchMatchcode)
            .then(response => {
                if (response.ok) {
                    dispatch(setCartHeaders({
                        id: orderID,
                        idreal: orderIDReal,
                        name: newName
                    }))
                    closeRenamePopup(newName)
                    setNewName('')
                }
           })
        } catch(error) {console.error(error)
        } finally {HideLoader()}
    }

    return (
       <form className="rename_cart__popup" onSubmit={SubmitNewName}>
            <div className="rename_cart__popup__white">
                <div className="rename_cart__popup__title">{t((isCreateCart || isCreateCartLocal) ? 'niki.b2b.cart.create' : 'niki.b2b.cartflow.renamecart')}</div>
                <input
                    className="form-control"
                        name="newcartname"
                        ref={cartRenameInputEl}
                        value={newName}
                        onChange={e => setNewName(e.target.value)}
                />
                {isNeedEnterNewName && <p>{t('niki.b2b.cart.enter_cart_name')}</p>}
            </div>
            <div className="rename_cart__popup__buttons">
                <button className="btn" type="button" onClick={onClosealert}>{t('niki.b2b.cartflow.cancel')}</button>
                <button className="btn btn-primary" type="submit">{t('niki.b2b.productcatalogs.save')}</button>
            </div>
        </form> 
    )
}