import React, { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { t } from "i18next"

import { PriceFormat, ShowLoader, HideLoader, DataPreloader } from "../functions.js"
import { getRequestOptions, getAccountBlocked } from '../helpers/apiHelper'
import { setErrorMessage } from '../reduxToolkit/allproduct'

import { AlertPopup } from "../components/AlertPopup"
import { BreadCrumbs } from "../components/BreadCrumbs"

import './../assets/css/debts.css'

import { ReactComponent as Invoices } from '../assets/svg/debtsinvoice.svg'
// import { ReactComponent as Orders } from '../assets/svg/debtsorders.svg'
// import { ReactComponent as PayNow } from '../assets/svg/debtspaynow.svg'

const LoadLedgersData = async () => {
    const searchURL = `${process.env.REACT_APP_API_DOMAIN}/b2b/entity-ledgers?search=entity_account_id%3A${localStorage.getItem('account.entity.id.real')}&realKey=1`
    const response = await fetch(searchURL, getRequestOptions())
    const json = await response.json()
    return json
}

const FormatDate = localDate => {
    const fullDate = new Date(localDate)
    return `${String(fullDate.getDate()).padStart(2, '0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}
const DateDayDifference = workingDate => {
    const nowDate = new Date()
    const date2 = new Date(workingDate)
    const diffTime = date2 - nowDate
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
}

export const Debts = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [overdueData, setOverdueData] = useState([])
    const [debtsData, setDebtsData] = useState([])
    const [totalOverdue, setTotalOverdue] = useState(0)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [isAccountBlockedModal, setIsAccountBlockedModal] = useState(false)


    useEffect(() => {
        // setDebtsData(DebtsList)
        ShowLoader()
        LoadLedgersData()
            .then(json => {
                if (json.data) {
                    let totalOverdueSumm = 0
                    setDataLoaded(true)
                    setDebtsData([])
                    setOverdueData([])
                    json.data.forEach(e => {
                        const differenceInDays = DateDayDifference(e.payment_at)
                        const tempObj = {
                            invoiceID: e.id,
                            realID: e.real_id,
                            invoice: `${e.document}-${e.serial} ${String(e.number).padStart(6, '0')}`,
                            summ: e.pending_amount,
                            days: 9,
                            processID: e.id,
                            SKUs: e.SKUs,
                            orderDate: FormatDate(e.created_at),
                            dueDate: FormatDate(e.payment_at),
                            difference: Math.abs(differenceInDays),
                            uuid: e.document_uuid
                        }
                        if (differenceInDays >= 0) {
                            setDebtsData(prevState => ([...prevState, tempObj]))
                        } else {
                            setOverdueData(prevState => ([...prevState, tempObj]))
                            totalOverdueSumm += e.credit_amount
                        }
                    })
                    setTotalOverdue(totalOverdueSumm)
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())

        try {
            getAccountBlocked().then(json => {
                typeof json?.blocked === 'boolean' && setIsAccountBlockedModal(!!json?.blocked)
            })
        } catch (error) {
            console.error(error)
        }
    }, [])

    const OpenPDF = (orderID) => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents/${orderID}/print?include=details`, getRequestOptions('GET', 'text/plain'))
            .then(response => response.text())
            .then(url => {
                window.open(`http://${url}`, '_blank', 'noreferrer')
            })
            .catch(error => {
                dispatch(setErrorMessage(t('niki.b2b.feedback.error_return_products') + ' ' + t('niki.b2b.feedback.contact_your_manager') + '.'))
                console.error(error)
            })
            .finally(() => HideLoader())
    }

    const DebtShowHide = e => {
        const WorkingClass = `.debts_list__item.${e.target.dataset.id}`
        document.querySelector(WorkingClass).classList.toggle('active')
    }

    return (
        <div className="content">
            <BreadCrumbs itemlist={[{ 'url': '/account/', 'title': t('niki.b2b.account.my') }, { 'url': '/debts/', 'title': t('niki.b2b.debts.h1') }]} />
            <div className="debts_content">
                <h1>{t('niki.b2b.debts.h1')}</h1>
                <h2 className="red">{t('niki.b2b.debts.overdue')}</h2>

                {!dataLoaded && <DataPreloader />}
                <div className="debts_list">
                    {dataLoaded && overdueData.length > 0 && overdueData.map((e, i) =>
                        <div className={`debts_list__item id${e.invoiceID.toLocaleLowerCase().replaceAll(' ', '_')}`} key={`debtslistitem${i}`}>
                            <div className="debts_list__item__first">
                                <strong>{t('niki.b2b.debts.invoice')}</strong>
                                <strong className="right">{PriceFormat(e.summ)}</strong>
                                <span>{e.invoice}</span>
                                <span className="debts_list__days_overdue">{t('niki.b2b.debts.overdueby')} {e.difference} {t('niki.b2b.debts.days')}</span>
                                <button
                                    className="debts_list__item__first__button"
                                    onClick={DebtShowHide}
                                    data-id={`id${e.invoiceID.toLocaleLowerCase().replaceAll(' ', '_')}`}
                                ></button>
                            </div>
                            <div className="debts_list__item__second">
                                <div className="debts_list__item__second__info">
                                    <span>{t('niki.b2b.debts.processid')}: {e.realID}</span>
                                    <span>{t('niki.b2b.debts.skus')}: {e.SKUs}</span>
                                    <span>{t('niki.b2b.debts.orderdate')}: {e.orderDate}</span>
                                    <span>{t('niki.b2b.debts.duedate')}: {e.dueDate}</span>
                                </div>
                                <div className="debts_list__item__second__buttons">
                                    <div></div>
                                    <button onClick={() => OpenPDF(e.uuid)} className="order__second__button"><Invoices />{t('niki.b2b.debts.invoice')}</button>
                                    <div></div>
                                    {/* <a href={`/process/${e.processID}/`} className="debts_list__item__second__button"><Invoices />{t('niki.b2b.process.h1')}</a>
                                    <a href={`/order/${e.invoiceID}/`} className="debts_list__item__second__button"><Orders />{t('niki.b2b.product.order')}</a>
                                    <button className="debts_list__item__second__button"><PayNow />{t('niki.b2b.debts.paynow')}</button> */}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="debts__total_overdue">
                    <div>{t('niki.b2b.debts.totaloverdue')}</div>
                    <div className="right">{PriceFormat(totalOverdue)}</div>
                </div>

                <h2>{t('niki.b2b.account.debts')}</h2>

                {!dataLoaded && <DataPreloader />}
                <div className="debts_list">
                    {dataLoaded && debtsData.length > 0 && debtsData.map((e, i) =>
                        <div className={`debts_list__item id${e.invoiceID.toLocaleLowerCase().replaceAll(' ', '_')}`} key={`debtslistitem${i}`}>
                            <div className="debts_list__item__first">
                                <strong>{t('niki.b2b.debts.invoice')}</strong>
                                <strong className="right">{PriceFormat(e.summ)}</strong>
                                <span>{e.invoice}</span>
                                <span className="debts_list__days">{t('niki.b2b.debts.duein')} {e.difference} {t('niki.b2b.debts.days')}</span>
                                <button
                                    className="debts_list__item__first__button"
                                    onClick={DebtShowHide}
                                    data-id={`id${e.invoiceID.toLocaleLowerCase().replaceAll(' ', '_')}`}
                                ></button>
                            </div>
                            <div className="debts_list__item__second">
                                <div className="debts_list__item__second__info">
                                    <span>{t('niki.b2b.debts.processid')}: {e.realID}</span>
                                    <span>{t('niki.b2b.debts.skus')}: {e.SKUs}</span>
                                    <span>{t('niki.b2b.debts.orderdate')}: {e.orderDate}</span>
                                    <span>{t('niki.b2b.debts.duedate')}: {e.dueDate}</span>
                                </div>
                                <div className="debts_list__item__second__buttons">
                                    <div></div>
                                    <button onClick={() => window.location.href = `/document/${e.uuid}`} className="order__second__button"><Invoices />{t('niki.b2b.debts.invoice')}</button>
                                    <div></div>
                                    {/* // todo 
                                    <a href={`/process/${e.processID}/`} className="debts_list__item__second__button"><Invoices />{t('niki.b2b.process.h1')}</a>
                                    <a href={`/order/${e.invoiceID}/`} className="debts_list__item__second__button"><Orders />{t('niki.b2b.product.order')}</a>
                                    <button className="debts_list__item__second__button"><PayNow />{t('niki.b2b.debts.paynow')}</button> */}

                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {isAccountBlockedModal && <AlertPopup text={t('niki.b2b.entity_account_blocked')} closeAlert={() => setIsAccountBlockedModal(false)}
                    towButtons={true} titleFirstButton={'niki.b2b.menu.contact'} onFirstButtonClick={() => navigate('/contact/')}
                />}
            </div>
        </div>
    )
}