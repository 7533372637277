import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { t } from "i18next"

import { clearCartContent, clearCartIdName, setUuidCart } from '../reduxToolkit/cartSlice'
import { setErrorMessage } from '../reduxToolkit/allproduct'
import { PriceFormat, VatByCartDetails, NetByCartDetails, ShowLoader, HideLoader, DataPreloader, setCartIdsToStorage } from "../functions.js"
import { getRequestOptions, deleteCartsItem, createNewCart } from '../helpers/apiHelper'

import { BreadCrumbs } from "../components/BreadCrumbs"
import { RenameCart } from '../components/MyShoppingCart/RenameCart'
import { DeleteCartAlert } from '../components/DeleteCartAlert'

import './../assets/css/shoppingcarts.css'

import { ReactComponent as More } from './../assets/svg/threedots.svg'
import { ReactComponent as Duplicate } from './../assets/svg/scduplicate.svg'
import { ReactComponent as Continue } from './../assets/svg/sccontinue.svg'
import { ReactComponent as Delete } from './../assets/svg/sctrash.svg'

const FormatDate = localDate => {
    const fullDate = new Date(localDate)
    return `${String(fullDate.getDate()).padStart(2, '0')}.${String(fullDate.getMonth() + 1).padStart(2, '0')}.${fullDate.getFullYear()}`
}

const LoadCartsData = async () => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id')}?include=status,company,cartDetails,items&realKey=1&orderBy=id&sortedBy=desc&showVat=true`
    const response = await fetch(URL,getRequestOptions())
    const json = await response.json()
    return json
}

export const ShoppingCarts = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const cartUuid = useSelector(state => state.cartContent.uuidCart)

    const h1popupEl = useRef(null)
    const moreMenuOverlayEl = useRef(null)
    const cartRenameOrCreateNewRef = useRef(null)
   
    const [data, setData] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [delCartID, setDelCartID] = useState('')
    const [selectCart, setSelectCart] = useState(null)
    const [isCreateNewCart, setIsCreateNewCart] = useState(false)
    const [isDeleteCartAlert, setIsDeleteCartAlert] = useState(false)
    
    const MoreClick = () => {
        h1popupEl.current.classList.toggle('active')
        moreMenuOverlayEl.current.classList.add('active')
    }
    const CloseMoreMenu = () => {
        h1popupEl.current.classList.remove('active')
        moreMenuOverlayEl.current.classList.remove('active')
    }

    const ExplandCollapseCartItem = e => {
        e.target.parentNode.parentNode.classList.toggle('active')
    }
    const DeleteCart = e => {
        const cartID = e.target.dataset.id
        setDelCartID(cartID)
        setIsDeleteCartAlert(true)
    }

    const confirmDeletion = () => {
        ShowLoader()
        deleteCartsItem(delCartID)
        .then(response => {
            if (response.ok) {
                setData(current => current.filter(item => item.id !== delCartID))
            }
        })
        .catch(error => console.error(error))
        .finally(() => {
            HideLoader()
            setIsDeleteCartAlert(false)
        })
    }

    useEffect(()=>{
        ShowLoader()
        LoadCartsData()
        .then(json => {
            setDataLoaded(true)
            setData([])
            json.data && json.data.forEach(e => {
                let summ = 0
                if (e.cartDetails && e.cartDetails.data && e.cartDetails.data.length > 0) {
                    e.cartDetails.data.forEach(el => summ += parseInt(el.qty)*parseFloat(el.unit_price))
                }
                const cartVat = (e.cartDetails && e.cartDetails.data && e.cartDetails.data.length > 0) ? VatByCartDetails(e.cartDetails.data) : 0
                const cartNet = (e.cartDetails && e.cartDetails.data && e.cartDetails.data.length > 0) ? NetByCartDetails(e.cartDetails.data) : 0
                const tempObj = {
                    id: e.id,
                    idReal: e.id_real,
                    uuid: e.uuid,
                    date: e.created_at,
                    summ: summ,
                    name: e.matchcode || t('niki.b2b.cartflow.cart'),
                    skus: (e.cartDetails && e.cartDetails.data && e.cartDetails.data.length) || 0,
                    gros: cartNet+cartVat,
                    vat: cartVat,
                    net: cartNet,
                }
                setData(prevState => ([...prevState, tempObj]))
            })
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }, [])
    
    useEffect(() => {
        !selectCart && data.length > 0 && setSelectCart(data[0])
    }, [selectCart, data])

    const onCreateNewCart = (name) => {  
        ShowLoader()
        createNewCart(name)
        .then(response => response.json())
        .then(json => {
            json?.message && dispatch(setErrorMessage(json?.message))
            if (json.data) {
                setCartIdsToStorage(json.data.uuid, json.data.id_real, json.data.id)

                const tempObj = {
                    id: json.data.id,
                    idReal: json.data.id_real,
                    uuid: json.data.uuid,
                    date: json.data.created_at,
                    name: json.data.matchcode || t('niki.b2b.cartflow.cart'),
                    summ: 0,
                    skus: 0,
                }
                setData([tempObj,...data])
                h1popupEl.current.classList.remove('active')
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const OpenCart = e => {
        if (cartUuid !== e.target.dataset.uuid) {
            dispatch(clearCartContent())
            dispatch(clearCartIdName())
            dispatch(setUuidCart(e.target.dataset.uuid))
            setCartIdsToStorage(e.target.dataset.uuid, e.target.dataset.idreal, e.target.dataset.id)
        }
        
        // window.location.href = '/myshoppingcart/'
        navigate('/myshoppingcart/')
    }

    const DuplicateCart = e => {
        const id = e.target.dataset.id
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/duplicate-cart-headers/${id}?include=cartDetails&realKey=1`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
                json?.message && dispatch(setErrorMessage(json?.message))
            if (json.data) {
                const tempObj = {
                    id: json.data.id,
                    idReal: json.data.real_id,
                    uuid: json.data.uuid,
                    date: json.data.created_at,
                    summ: e.target.dataset.summ,
                    gros: e.target.dataset.gros,
                    skus: e.target.dataset.skus,
                    vat: e.target.dataset.vat,
                    net: e.target.dataset.net
                }
                setData([tempObj,...data])
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const ShowRenamePopup = () => {
        CloseMoreMenu()
        cartRenameOrCreateNewRef.current.classList.add('active')
    } 
    const ShowCreateNewCartPopup = () => {
        setIsCreateNewCart(true)
        CloseMoreMenu()
        cartRenameOrCreateNewRef.current.classList.add('active')
    } 
    const CloseRenamePopup = (name) => {
        if (name !== undefined) {
            if (isCreateNewCart) {
                onCreateNewCart(name)
            } else if (name){
                setData(prevState => (prevState.map(c => c.uuid === selectCart.uuid ? { ...c, name: name } : c)))
            }
        }
        setIsCreateNewCart(false)
        cartRenameOrCreateNewRef.current.classList.remove('active')
    }

    return (
        <div className="content">
            <BreadCrumbs itemlist={[{'url':'/account/','title':t('niki.b2b.menu.myaccount')},{'url':'/shoppingcarts/','title':t('niki.b2b.cart.shoppingcarts')}]} />
            <div className="shopping_carts__content">
                <h1>
                    {t('niki.b2b.cart.myshoppingcarts')}
                    <span className="more_button"> 
                            <button onClick={MoreClick}><More /></button>
                            <div className="more_menu" ref={h1popupEl}>
                                <button onClick={ShowCreateNewCartPopup}>
                                    {t('niki.b2b.cartflow.createnewcart')}
                                </button>
                            <button onClick={ShowRenamePopup}>
                                    {t('niki.b2b.cartflow.rename')}
                                </button>
                            </div>
                        </span>
                </h1>

                {!dataLoaded && <DataPreloader />}

                <div className="shopping_carts__list">
                    {dataLoaded && data.length > 0 && data.map((e, i) =>
                    <div className={["shopping_carts__item", e.uuid === selectCart?.uuid && "shopping_cart__item_selecte"].join(' ')} key={`shoppingcart${i}`} onClick={() => setSelectCart(e)}>
                        <div className="shopping_carts__item__visible">
                            <div className="shopping_carts__item__info">
                                <div><strong>{e.idReal} {e.name ? `${e.name}` : ` ${t('niki.b2b.cart.shoppingcart')}`} </strong></div>
                                <div><strong>{PriceFormat(e.summ)}</strong></div>
                            </div>
                            <div className="shopping_carts__item__info">
                                <div>{t('niki.b2b.product.date')}: {FormatDate(e.date)}</div>
                                <div>{t('niki.b2b.debts.skus')}: {e.skus}</div>
                            </div>
                            <button className="shopping_carts__item__button" onClick={ExplandCollapseCartItem}></button>
                        </div>
                        <div className="shopping_carts__item__hidden">
                            <div className="shopping_carts__item__info in_hidden">
                                <div>{t('niki.b2b.product.gross')}: {PriceFormat(e.gros)}</div>
                                <div>{t('niki.b2b.product.vat')}: {PriceFormat(e.vat)}</div>
                            </div>
                            <div className="shopping_carts__item__hidden__buttons">
                                <button
                                    className="shopping_carts__item__hidden__button"
                                    onClick={DuplicateCart}
                                    data-id={e.id}
                                    data-skus={e.skus}
                                    data-vat={e.vat}
                                    data-net={e.net}
                                    data-gros={e.gros}
                                    data-summ={e.summ}
                                ><Duplicate />{t('niki.b2b.orders.duplicate')}</button>
                                <button
                                    className="shopping_carts__item__hidden__button"
                                    onClick={OpenCart}
                                    data-id={e.id}
                                    data-idreal={e.idReal}
                                    data-uuid={e.uuid}
                                ><Continue />{t('niki.b2b.shoppingcarts.continue')}</button>
                                <button
                                    className="shopping_carts__item__hidden__button"
                                    data-id={e.id}
                                    onClick={DeleteCart}
                                ><Delete />{t('niki.b2b.shoppingcart.delete')}</button>
                            </div>
                        </div>
                    </div>
                )}
                </div>
            </div>
            <div className="more_menu__overlay" onClick={CloseMoreMenu} ref={moreMenuOverlayEl}></div>
            <div className="rename_cart__wrapper" ref={cartRenameOrCreateNewRef}>
                <RenameCart orderID={selectCart?.id} orderIDReal={selectCart?.idReal} closeRenamePopup={CloseRenamePopup} uuid={selectCart?.uuid} isCreateCart={isCreateNewCart}/>
                <div className="rename_cart__overlay" onClick={CloseRenamePopup}></div>
            </div>
            {isDeleteCartAlert && <DeleteCartAlert closeAlertPopup={() => setIsDeleteCartAlert(false)} confirmDeletion={confirmDeletion} />}
        </div>
    )
}