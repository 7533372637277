import React, { useEffect, useState, useCallback } from "react"
import { useParams, useNavigate, NavLink } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { t } from "i18next"

import { priceLineID } from '../config.js'
import { getRequestOptions } from '../helpers/apiHelper'
import { clearCartContent, clearCartIdName, setUuidCart } from '../reduxToolkit/cartSlice'
import { setFormShowOrder } from '../reduxToolkit/productsSlice'
import { setErrorMessage } from '../reduxToolkit/allproduct'
import { PriceFormat, GetMainImageCart, GetHistoryData, ShowLoader, HideLoader, LangFix, DataPreloader, setCartIdsToStorage, returnSortedCartData } from "../functions.js"

import { BreadCrumbs } from "../components/BreadCrumbs"
import { OrderHistoryPopup } from "../components/OrderHistoryPopup.jsx"
import { SortingProductsData } from "../components/SortingProductsData"
import LoadingDots from "../components/LoadingDots/LoadingDots"

import './../assets/css/orders.css'

import { ReactComponent as OrderInfo } from './../assets/svg/orderinfo.svg'
import { ReactComponent as Download } from './../assets/svg/orderdownload.svg'
import { ReactComponent as Complaint } from './../assets/svg/complaint.svg'
import { ReactComponent as History } from './../assets/svg/orderhistory.svg'
import { ReactComponent as Duplicate } from './../assets/svg/orderduplicate.svg'
import { ReactComponent as Sort } from './../assets/svg/ordersort.svg'

const DateFormat = localDate => localDate && localDate.length > 0 && localDate.split(' ')[0].replaceAll('/','.')
const GetOrderSumm = data => {
    let summ = 0
    // in test json in data goes unit_price = null so we use «|| 1»
    data.forEach(e => summ += parseFloat(e.quantity) * parseFloat(e.unit_price || 1))
    return summ
}

let cartSummOld = 0
let cartSumm = 0

const FiledAdditionalNote = ({orderData}) => {
    const [openPopup, setOpenPopup] = useState(false)
    
    useEffect(() => {
        let timer;

        if (openPopup) {
            clearTimeout(timer);
            timer = setTimeout(() => setOpenPopup(false), 5000)
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [openPopup])

    return (
        <div className={["order_page__info__table", "order_page__info_item_additionalnote"].join(' ')}>
            {openPopup && <span>{orderData.additional_info}</span>}
            <div>{t('niki.b2b.orders.additionalnote')}</div>
            <button onClick={() => setOpenPopup(true)}><OrderInfo /></button>
        </div>   
    ) 
}

export const Order = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const orderID = id

    const currentUrl = window.location
    const isDocument = currentUrl.pathname.includes('document')

    const formShowOrder = useSelector(state => state.productsContent.formShowOrder)
    
    const [orderData, setOrderData] = useState({status: ''})
    const [orderProducts, setOrderProducts] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [isOpenHistoryProduct, setIsOpenHistoryProduct] = useState(false)
    const [idProductForHistory, setIdProductForHistory] = useState('')
    const [isSortingProducts, setIsSortingProducts] = useState(false)
    const [isSortingProductsClose, setIsSortingProductsClose] = useState(false)
    const [selectSortByItem, setSelectSortByItem] = useState('')
    const [sortedOrderData, setSortedOrderData] = useState([])

    const ProductExplandCollapse = e => {
        const WorkingSelector = `.order_page__product.${e.target.dataset.id}`
        document.querySelector(WorkingSelector).classList.toggle('active')
    }

    useEffect(() => {
        setOrderProducts([])
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents/${orderID}?include=details.item,details.item.itemDescriptions,details.item.itemFamily,status&realKey=true&showVat=true&source_price_id[]=${priceLineID}&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            setDataLoaded(true)
            json.data && setOrderData({
                id: json.data.id,
                idReal: json.data.id_real,
                orderDate: json.data.created_at,
                status: json.data.status?.data?.matchcode,
                deliveryAt: json.data.delivery_at,
                processID: json.data.process_id,
                processIDReal: json.data.process_id_real,
                totalSKUs: (json.data.details?.data && json.data.details?.data?.length) || 0,
                summ: GetOrderSumm(json.data.details.data),
                totalVat: json.data.total_tax_amount,
                totalNet: json.data.total_net_amount,
                totalGross: json.data.total_gross_amount,
                key: `${json.data.key} - ${json.data.serial}`,
                externalId: json.data.external_id || '',
                additional_info: json.data.footer_message,
                invoice: `${json.data.key} ${json.data.serial} - ${String(json.data.number).padStart(6,'0')}`,
            })
            json.data.details && json.data.details?.data && json.data.details?.data?.forEach(e => {
                const returnShortDescription = () => e.item.data.itemDescriptions?.data?.filter(i => i.locale === LangFix(localStorage.getItem('locale')))[0]?.short_description                
                let price = e.unit_price || 0
                let priceOld = 0
                let prodGross = parseFloat(e.item?.data?.itemPrices?.data[0]?.tax_included_price) || 0
               
                const prodVat = prodGross ? prodGross - price : 0
                const prodQty = e.quantity || 0
                const tempObj = {
                    id: e.id,
                    productid: e.item?.data?.id,
                    itemID: e.item?.data?.item_id,
                    family: e.item?.data?.itemFamily?.data?.description || 'zz',
                    kinds_type: e.item?.data?.itemKinds?.data[0]?.real_id || 5,
                    matchCode: e.item.data.itemDescriptions && returnShortDescription()
                        ? returnShortDescription()
                        : e.description,
                    image: GetMainImageCart(e.item.data.attachments,e.item.data.attachment_id),
                    quantity: prodQty,
                    price: price,
                    priceOld: priceOld,
                    unitCode: e.unit_code_name || e.unit_code,
                    group: (e.item.data && e.item.data.itemCategories && e.item.data.itemCategories.data && e.item.data.itemCategories.data[0] && e.item.data.itemCategories.data[0] && e.item.data.itemCategories.data[0].name) || null,
                    type: (e.item.data && e.item.data.itemKinds && e.item.data.itemKinds.data && e.item.data.itemKinds.data[0] && e.item.data.itemKinds.data[0].name) || null,
                    VAT: prodVat.toFixed(2),
                    qtyInBox: e.quantity,
                }
                cartSummOld += (priceOld > 0 ? priceOld : price)*prodQty
                cartSumm += price*prodQty
                setOrderProducts(prevState => ([...prevState, tempObj]))
            })
        })
        .catch(error => console.log(error))
        .finally(() => HideLoader())
        
        return (() => dispatch(setFormShowOrder('')))
        // eslint-disable-next-line
    }, [])
    
    useEffect(() => {
        if (selectSortByItem && orderProducts?.length > 0) {
            const resSort = returnSortedCartData(selectSortByItem, orderProducts)
            setSortedOrderData(resSort)
        } 
    }, [selectSortByItem, orderProducts])

    const OpenPDF = e => { 
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents/${orderID}/print?include=details`,getRequestOptions('GET','text/plain'))
        .then(response => response.text())
        .then(url => {
            window.open(`http://${url}`,'_blank','noreferrer')
        })
            .catch(error => {
                dispatch(setErrorMessage(t('niki.b2b.feedback.error_return_products')+ ' ' + t('niki.b2b.feedback.contact_your_manager')+'.'))
                console.error(error)
            })
        .finally(() => HideLoader())
    }

    /* scroll direction */
    const [y,setY] = useState(window.scrollY)
    const handleNavigation = useCallback(e => {
        const window = e.currentTarget
        y > window.scrollY ? document.body.classList.add('fixed_bottom') : document.body.classList.remove('fixed_bottom')
        setY(window.scrollY)
    },[y])
    useEffect(() => {
        setY(window.scrollY)
        window.addEventListener("scroll", handleNavigation)
        return () => {
            window.removeEventListener("scroll", handleNavigation)
        }
    }, [handleNavigation])
    /* scroll direction */

    const ShowProdHistory = e => {
        setIsOpenHistoryProduct(true)
        setIdProductForHistory(e.target.dataset.id)
    }

    const showHideSortPanel = () => {
        if (isSortingProducts) {
            setIsSortingProductsClose(true)
        } else {
            setIsSortingProducts(true)
            setIsSortingProductsClose(false)
        }
    }

    const duplicateClick = (e) => {
        setIsSortingProductsClose(true)
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/document-to-cart/${e.target.dataset.uuid}?realKey=true`,getRequestOptions('POST'))
        .then(response => response.json())
        .then(json => {
            if (json.message && json.errors) {
                dispatch(setErrorMessage(json?.message))
            } else if (json.uuid) {
                dispatch(clearCartContent())
                dispatch(clearCartIdName())
                dispatch(setUuidCart(json.uuid))
                setCartIdsToStorage(json.uuid, json.real_id, json.id)
                navigate('/myshoppingcart/')
            }
        })
        .catch(error => console.error(error))
        .finally(() => HideLoader())
    }

    const onProductClick = () => {
        localStorage.setItem('url.back', `${window.location.pathname}`)
    }

    const orderProduct = (e,i) => {
        return (
            <div key={`orderproduct${i}`} className={[`order_page__product`, `id${e.id.toLowerCase().replaceAll(' ', '_')}`].join(' ')}>
                <div className="order_page__product__first">
                    <NavLink  to={`/productpage/${e.productid}/`} onClick={onProductClick} className="order_page__product__first__image">
                        <img src={e.image} alt={e.matchCode} loading="lazy" />
                    </NavLink>
                    <div>
                        <NavLink to={`/productpage/${e.productid}/`} onClick={onProductClick} className="order_page__product__first__link">{e.itemID} - {e.matchCode}</NavLink>
                        <div className="order_page__product__first__info">
                            <div>
                                {GetHistoryData(e.productid).length > 0 &&
                                    <button type="button" onClick={ShowProdHistory} data-id={e.productid}>
                                        <History />
                                    </button>
                                }
                            </div>
                            <div>{(parseFloat(e.priceOld) > 0) && <span className="order_page__product__first__info__discount">-{Math.round((e.priceOld - e.price) / e.priceOld * 100)}%</span>}</div>
                            <strong className="center">{PriceFormat(parseFloat(e.price) * parseInt(e.quantity))}</strong>
                            <div className="order_page__product__first__info__qtyincart">{e.quantity}</div>
                            <button
                                className="order_page__product__first__info__button"
                                onClick={ProductExplandCollapse}
                                data-id={`id${e.id.toLowerCase().replace(' ', '_')}`}
                            ></button>
                        </div>
                    </div>
                </div>
                <div className="order_page__product__second">
                    <div>
                        {e.group && <div className="marginbottom5">{t('niki.b2b.shoppingcart.group')}: {e.group}</div>}
                        {e.type && <div className="marginbottom5">{e.type}</div>}
                        <div>{e.unitCode}: {t('niki.b2b.cartflow.box')}: {e.qtyInBox}</div>
                    </div>
                    <div>
                        <div className="marginbottom5">{t('niki.b2b.product.unitprice')}: {PriceFormat(e.price)}</div>
                        <div>{t('niki.b2b.product.vat')}: {PriceFormat(e.VAT)}</div>
                    </div>
                    <div className="relative">
                        <div className="order_page__product__second__qtyincart">{e.quantity}</div>
                    </div>
                </div>
                {GetHistoryData(e.productid).length > 0 && isOpenHistoryProduct && e.productid === idProductForHistory &&
                    <OrderHistoryPopup
                        matchcode={e.matchCode}
                        imgsrc={e.image}
                        qty_in_box={e.qtyInBox}
                        sale_unit_code={e.unitCode}
                        item_id={e.itemID}
                        product_id={e.productid}
                        setIsOpenHistoryProduct={setIsOpenHistoryProduct}
                    />
                }
            </div>
        )
    }

    return (
        <div className="content">
            {isDocument
                ? <BreadCrumbs itemlist={[
                    { 'url': '/account/', 'title': t('niki.b2b.menu.myaccount') },
                    { 'url': formShowOrder ? `/${formShowOrder}/` : '/debts/', 'title': formShowOrder ? t('niki.b2b.menu.myinvoices') : t('niki.b2b.debts.h1') },
                    { 'url': `/document/${orderID}/`, 'title': `${t('niki.b2b.document')} ${orderData.idReal ? `${orderData.key} ${orderData.idReal}` : ''}` }
                ]} />
                : <BreadCrumbs itemlist={[
                    { 'url': '/account/', 'title': t('niki.b2b.menu.myaccount') },
                    { 'url': '/orders/', 'title': t('niki.b2b.account.myorders') },
                    { 'url': `/order/${orderID}/`, 'title': `${t('niki.b2b.feedback.orderid')} ${orderData.idReal ? `${orderData.key} ${orderData.idReal}` : ''}` }
                ]} />
            }
            <div className="orders_content">
                <div className="orders_content__wrapper_title">{isDocument ? <h2>{t('niki.b2b.document')}</h2> : <h2>{t('niki.b2b.feedback.orderid')}</h2>}
                    {orderData.key || orderData.idReal
                        ? <h2>{orderData.key} {orderData.idReal}</h2>
                        : <div className="wrapper_loading_dots"><LoadingDots/></div>
                    }
                </div>

                <div className="order_page__info">
                    <h2>{t('niki.b2b.orders.status')}</h2>
                    <div className="order_page__info__table">
                        <div>{t('niki.b2b.debts.orderdate')}</div>
                        <div>{DateFormat(orderData.orderDate)}</div>
                    </div>
                    <div className="order_page__info__table">
                        <div>{t('niki.b2b.orders.deliverystatus')}</div>
                        <div className={[`status`,orderData.status.toLowerCase()].join(' ')}>{orderData.status}</div>
                    </div>
                    <div className="order_page__info__table">
                        <div>{t('niki.b2b.orders.deliverydate')}</div>
                        <div>{DateFormat(orderData.deliveryAt)}</div>
                    </div>
                    <div className="order_page__info__table">
                        <div>{t('niki.b2b.orders.trackinginfo')}</div>
                        <div>Monolith</div>
                    </div>
                    <div className="order_page__info__table">
                        <div>{t('niki.b2b.orders.invoiceno')}</div>
                        <div> {isDocument ? orderData.idReal : orderData.externalId}</div>
                    </div>
                    <div className="order_page__info__table">
                        <div>{t('niki.b2b.debts.processid')}</div>
                        <div>{orderData.processIDReal}</div>
                    </div>
                    {orderData.additional_info && <FiledAdditionalNote orderData={orderData} />}
                    <div className="order_page__info__buttons">
                        <button
                            className="order_page__info__button"
                            onClick={OpenPDF}
                            data-uuid={orderID}
                        ><Download />
                            {/* {isDocument ? t('niki.b2b.document') : t('niki.b2b.product.order')} */}
                            {t('niki.b2b.process.download')}
                        </button>
                        {/* <button className="order_page__info__button"><Download />{t('niki.b2b.debts.invoice')}</button> */}
                        <button className="order_page__info__button" onClick={() => navigate('/feedback/')}><Complaint />{t('niki.b2b.order.complaint')}</button>
                    </div>
                </div>

                {!dataLoaded && <DataPreloader />}
                <div className="order_page__products">
                    {dataLoaded && orderProducts.length > 0 
                        ? selectSortByItem && sortedOrderData.length > 0
                            ? sortedOrderData.map((e, i) => orderProduct(e,i))
                            : orderProducts.map((e, i) => orderProduct(e,i))
                        : null
                    }
                </div>

            </div>

            <div className="order_page__fixed">
                <div className="order_page__fixed__white">
                    <div>
                        <strong className="block">{t('niki.b2b.cartflow.totalskus')}</strong>
                        <strong className="block">{t('niki.b2b.orders.totalnet')}</strong>
                        <strong className="block">{t('niki.b2b.orders.totalvat')}</strong>
                        {cartSumm !== cartSummOld
                        &&
                        <strong className="block red">{t('niki.b2b.orders.saved')}</strong>
                        }
                    </div>
                    <div className="right">
                        <div>{orderData.totalSKUs}</div>
                        <div>{PriceFormat(orderData.totalNet)}</div>
                        <div>{PriceFormat(orderData.totalVat)}</div>
                        {cartSumm !== cartSummOld && <div className="red">{PriceFormat(cartSummOld-cartSumm)}</div>}
                    </div>
                    <div className="order_page__fixed__white__last">
                        <div>{t('niki.b2b.cart.carttotal')}<strong>{PriceFormat(orderData.totalGross)}</strong></div>
                    </div>
                </div>
                <div className="order_page__fixed__grey">
                    <button className={isSortingProducts ? "btn btn-lightgrey" : "sorting__products_button"} disabled={orderProducts.length === 0} onClick={showHideSortPanel}>
                        <Sort />
                    </button>
                    <button data-uuid={orderID} className="btn btn-primary" onClick={duplicateClick}>{t('niki.b2b.orders.duplicate')}<Duplicate /></button>
                </div>
            </div>
            {isSortingProducts && <SortingProductsData isSortingProductsClose={isSortingProductsClose} setIsSortingProducts={setIsSortingProducts} selectSortByItem={selectSortByItem} setSelectSortByItem={setSelectSortByItem} />}
        </div>
    )
}