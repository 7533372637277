import React, { useEffect, useState } from "react"
import { NavLink } from 'react-router-dom'
import { t } from "i18next"
import { FixCompanyName, getBannerDataLogin, ShowLoader, HideLoader } from "../functions.js"
import { companyUrlAddress } from '../config'

import { LangSelector } from "./LangSelector"
import { LoginHeader } from "./LoginHeader"


import { AlertPopup } from '../components/AlertPopup'
import { getRequestOptionsNoAuth, fetchDataPhoneOrEmail} from '../helpers/apiHelper'

import "../assets/css/login.css"

import { ReactComponent as LoginSubmit } from '../assets/svg/loginpagesubmit.svg'
import 'react-phone-input-2/lib/style.css'

export const LoginEmail = () => { 
    const [inputEmail,setInputEmail] = useState('')
    const [noEmailinBase, setNoEmailinBase] = useState(false)
    const [isOpenEmailEmptyAlert, setIsOpenEmailEmptyAlert] = useState(false)
    
    const SubmitEmail = e => {
        e.preventDefault()
        inputEmail.trim().length === 0 && setIsOpenEmailEmptyAlert(true)
        if (inputEmail.trim().length > 0) {
            const requestOptions = getRequestOptionsNoAuth('POST', null, 'application/json')
            requestOptions.body = JSON.stringify({email: inputEmail})
            
            ShowLoader()
            fetchDataPhoneOrEmail(`${process.env.REACT_APP_API_DOMAIN}/b2b/login/email-api`,requestOptions)
            .then(response => {
                if (response) {
                    localStorage.setItem('account.user.email',inputEmail)
                    window.location.href = '/enter-code/?from=Email'
                } else {
                    setNoEmailinBase(true)
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }
    const [bannerOne, setBannerOne] = useState({})
    
    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('banners_data'))
        data && data.forEach(e => {
            e.content_subtype_id === 'O5D1YKnGOndeJ9qv' && e.contentType?.data?.real_id === 1 && setBannerOne(getBannerDataLogin(e))
        })
    }, [])
    
    return (
        <>
            <div className="login__wrapper">
                <LoginHeader />
                <form autoCapitalize="off" autoComplete="off" autoCorrect="off" className="login__content" onSubmit={SubmitEmail}>
                    <div className="login__header__title">{t('niki.b2b.login.into')}<br />{FixCompanyName(t('niki.b2b.login.monolithunitedkingdom'))}</div>
                    <div className="dont_have_an_account">{t('niki.b2b.login.donthave')} <a href={companyUrlAddress} target="_blank" rel="noopener noreferrer">{t('niki.b2b.login.signup')}</a></div>
                    {noEmailinBase && <div className="alert alert-danger">{t('niki.b2b.login.emailisnotcorrect')}.</div>}
                    <div className="email_input">
                        <input
                            type="email"
                            className="form-control"
                            placeholder={t('niki.b2b.login.emailaddress')}
                            onChange={e => setInputEmail(e.target.value)}
                            value={inputEmail}
                            autoFocus
                        />
                    </div>
                    <div className="email_submit"><button className="btn" type="submit">{t('niki.b2b.login.login')}<LoginSubmit /></button></div>
                    <div className="forgot_your_email">{t('niki.b2b.login.forgotemail')} <NavLink to="/recover-email/">{t('niki.b2b.login.recover')}</NavLink></div>
                    <ul className="privacy_policy__disclosures">
                        <li><NavLink to="/privacy-policy/">{t('niki.b2b.footer.privacypolicy')}</NavLink></li>
                        <li><NavLink to="/mandatory-disclosures/">{t('niki.b2b.login.disclosures')}</NavLink></li>
                    </ul>
                    <div className="prefer_login_by_sms">{t('niki.b2b.login.prefersms')} <NavLink to="/">{t('niki.b2b.login.now')}</NavLink></div>
                    <LangSelector />
                </form>
                <a href={bannerOne.url} target={bannerOne.target} rel="noopener noreferrer" className="login__banner"><img src={bannerOne.image} alt={bannerOne.title} /></a>
            </div>
            <div className="loader"></div>
            {isOpenEmailEmptyAlert && <AlertPopup text={t('niki.b2b.login.emailempty')} closeAlert={() => setIsOpenEmailEmptyAlert(false)}/>}
        </>
    )
}