import React, { useEffect, useState} from "react"
import { useParams, NavLink } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { t } from "i18next"

import { PriceFormat, ShowLoader, HideLoader, DataPreloader, scrollTop } from "../functions"
import { priceLineID } from "../config"
import { getRequestOptions } from '../helpers/apiHelper'
import { setFormShowOrder } from '../reduxToolkit/productsSlice'

import { BreadCrumbs } from "../components/BreadCrumbs"

import './../assets/css/process.css'

// import { ReactComponent as Order } from './../assets/svg/processorder.svg'
import { ReactComponent as InvoiceReturn } from './../assets/svg/processinvoicereturn.svg'
import { ReactComponent as Download } from './../assets/svg/processdownload.svg'

const DateFormat = localDate => localDate && localDate.length > 0 && localDate.split(' ')[0]?.replaceAll('/','.')

const OpenPDF = e => {
    const uuid = e.target.dataset.uuid
    ShowLoader()
    fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents/${uuid}/print?include=details`,getRequestOptions('GET', 'text/plain'))
    .then(response => response.text())
    .then(url => {
        window.open(`http://${url}`,'_blank','noreferrer')
    })
    .catch(error => console.error(error))
    .finally(() => HideLoader())
}
const defaultProcessData = {
    matchcode: t('niki.grid.none'),
    startedBy: t('niki.grid.none'),
    dateOpened: t('niki.grid.none')
}

const defaultBalanceData = {
    order_total: 0,
    delivery_total: 0,
    invoice_total: 0,
    returns: 0,
    credit: 0,
}

export const Process = () => {
    const { id } = useParams()
    const processID = id
    const dispatch = useDispatch()

    const [isTableDataLoaded, setTableDataLoaded] = useState(false)
    const [tableData,setTableData] = useState([])
    const [isProcessDataLoaded, setProcessDataLoaded] = useState(false)
    const [processData,setProcessData] = useState(defaultProcessData)
    const [balanceData,setBalanceData] = useState(defaultBalanceData)

    const ProcessExplandCollapse = e => {
        const ActiveElement = `.process_document.${e.target.dataset.id}`
        document.querySelector(ActiveElement).classList.toggle('active')
    }

    useEffect(()=>{
        ShowLoader()
        setProcessDataLoaded(true)
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/transaction-processes/${processID}?entity_account_id=${localStorage.getItem('account.entity.id')}&include=transactionProcessStatus,creator,entity,modifier`,getRequestOptions())
        .then(response => response.json())
            .then(json => {
            json.data && setProcessData({
                matchcode: json.data.matchcode,
                startedBy: json.data?.creator?.data?.UserID && json.data.creator.data.UserID === 1
                    ? t('niki.b2b.system') 
                    : json.data.creator.data.Name,
                dateOpened: json.data.created_at,
            })
            setProcessDataLoaded(false)
        })
        .catch(error => {
            setProcessDataLoaded(false)
            console.error(error)
        })
        .finally(() => HideLoader())
        scrollTop()
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        ShowLoader()
        setTableDataLoaded(true)
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/documents?searchFields=account_id%3A%3D&search=account_id%3A${localStorage.getItem('account.entity.id.real')}&realKey=1&process_id=${processID}&realKey=1&include=status,details&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            let orderTotal = 0
            let invoiceTotal = 0
            let returnsTotal = 0
            let creditTotal = 0
            setTableData([])
            json.data && json.data.forEach(e => {
                const gross = parseFloat(e.total_tax_included_amount) || 0
                const tempObj = {
                    processID: e.process_id,
                    processIDReal: e.process_id_real,
                    documentID: e.id,
                    documentIDReal: e.id_real,
                    date: e.created_at,
                    SKUs: (e.details && e.details.data && e.details.data.length) || 0,
                    Gros: gross,
                    document_serial: `${e.key} - ${e.serial}/${e.number}`,
                    VAT: e.total_tax_amount,
                    Net: e.total_net_amount,
                    uuid: e.uuid,
                }
                setTableData(prevState => ([...prevState, tempObj]))
                if (e.key === 'NE') orderTotal += gross
                if (e.key === 'SRET') returnsTotal += gross
                if (e.key === 'FAC' || e.key === 'FAC2') invoiceTotal += gross
                if (e.key === 'NC' || e.key === 'NCW' || e.key === 'NCM') creditTotal += gross
            })
            setBalanceData({
                ...balanceData,
                order_total: orderTotal,
                delivery_total: 0,
                invoice_total: invoiceTotal,
                returns: returnsTotal,
                credit: creditTotal,
            })
            setTableDataLoaded(false)
        })
        .catch(error => {
            setTableDataLoaded(false)
            console.error(error)
        })
        .finally(() => HideLoader())
        // eslint-disable-next-line
    }, [])
    
    return (
        <div className="content">
            <BreadCrumbs itemlist={[
                {'url':'/account/','title':t('niki.b2b.menu.myaccount')},
                {'url':`/process/${processID}/`,'title':`${t('niki.b2b.process.h1')} ${processData.matchcode}`},
            ]} />
            <div className="process_content">
                
                <h1>{t('niki.b2b.process.h1')} {processData.matchcode}</h1>

                <div className="process_info">
                    <h2>{t('niki.b2b.process.information')}</h2>
                    <div className="process_info__table">
                        <div>{t('niki.b2b.process.startedby')}</div>
                        {isProcessDataLoaded
                            ? <div><span className="animated_placeholder inline-block width130px"></span></div>
                            : <div>{processData.startedBy}</div> 
                        }
                    </div>
                    <div className="process_info__table">
                        <div>{t('niki.b2b.process.dateopened')}</div>
                        {isProcessDataLoaded
                            ? <div><span className="animated_placeholder inline-block width130px"></span></div>
                            : <div>{processData.dateOpened}</div> 
                        }
                    </div>
                    <div className="process_info__table">
                        <div>{t('niki.b2b.process.dateclosed')}</div>
                        {isProcessDataLoaded
                            ? <div><span className="animated_placeholder inline-block width130px"></span></div>
                            : <div>{t('niki.grid.none')}</div>
                        }
                    </div>
                </div>

                <div className="process_info last">
                    <h2>{t('niki.b2b.process.balance')}</h2>
                    <div className="process_info__table">
                        <div>{t('niki.b2b.process.ordertotal')}</div>
                        <div>{PriceFormat(balanceData.order_total)}</div>
                    </div>
                    <div className="process_info__table">
                        <div>{t('niki.b2b.process.deliverytotal')}</div>
                        <div>{PriceFormat(balanceData.delivery_total)}</div>
                    </div>
                    <div className="process_info__table">
                        <div>{t('niki.b2b.process.invoicetotal')}</div>
                        <div>{PriceFormat(balanceData.invoice_total)}</div>
                    </div>
                    <div className="process_info__table">
                        <div>{t('niki.b2b.process.returns')}</div>
                        <div>{PriceFormat(balanceData.returns)}</div>
                    </div>
                    <div className="process_info__table">
                        <div>{t('niki.b2b.process.credit')}</div>
                        <div>{PriceFormat(balanceData.credit)}</div>
                    </div>
                </div>

                <div className="process_documents">
                    <h2>{t('niki.b2b.process.documents')}</h2>

                    {isTableDataLoaded && <DataPreloader qtyRows={3} />}

                    {tableData.length > 0 && tableData.map((e,i)=>
                        <div key={`document${i}`} className={[`process_document`,`id${e.documentID?.toLowerCase()?.replace(' ','_')}`].join(' ')}>
                            <div className="process_document__first">
                                <strong>ID</strong>
                                <strong>{t('niki.b2b.product.date')}</strong>
                                <strong className="right">{PriceFormat(e.Net)}</strong>
                                <span>{e.processIDReal}</span>
                                <span>{DateFormat(e.date)}</span>
                                <span className="right"><span className="process_document__first__type">{e.type}</span></span>
                                <button
                                    className="process_document__first__button"
                                    onClick={ProcessExplandCollapse}
                                    data-id={`id${e.documentID?.toLowerCase()?.replace(' ','_')}`}
                                ></button>
                            </div>
                            <div className="process_document__second">
                                <div className="process_document__second__info">
                                    <span>{t('niki.b2b.debts.skus')}: {e.SKUs}</span>
                                    <span>{t('niki.b2b.product.gross')}: {PriceFormat(e.Gros)}</span>
                                    <span>{t('niki.b2b.product.vat')}: {PriceFormat(e.VAT)}</span>
                                </div>
                                <div className="process_document__second__buttons">
                                    <NavLink to={`/document/${e.uuid}`} onClick={() => {dispatch(setFormShowOrder(`process/${processID}`))}} className="process_document__second__button">
                                        <InvoiceReturn /> {e.document_serial}
                                    </NavLink>
                                    <button
                                        data-uuid={e.uuid}
                                        onClick={OpenPDF}
                                        className="process_document__second__button"
                                    ><Download />{t('niki.b2b.process.download')}</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}