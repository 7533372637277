import React, { useRef, useEffect, useState, useCallback }  from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { t } from "i18next"

import { PriceFormat, ShowLoader, HideLoader, getCartProducts, setCartIdsToStorage, returnSortedCartData } from '../functions.js'
import { getRequestOptions, deleteCartsItem, getAllCartsData, includeForProduct, createNewCart, cartHeaderInclude } from '../helpers/apiHelper'
import { addCartProduct, clearCartContent, setIsLoaded, clearCartIdName, setCartHeaders } from "../reduxToolkit/cartSlice"
import { setErrorMessage } from '../reduxToolkit/allproduct'
import { companySupplier, priceLineID } from '../config.js'

import { ProductDetails } from '../components/ProductDetails'
import { ProductListItem } from '../components/MyShoppingCart/ProductListItem'
import { RenameCart } from '../components/MyShoppingCart/RenameCart'
import { AddProductToCart } from '../components/MyShoppingCart/AddProductToCart'
import { BreadCrumbs } from "../components/BreadCrumbs"
import { SortingProductsData } from '../components/SortingProductsData'
import { DeleteCartAlert } from '../components/DeleteCartAlert'

import './../assets/css/shoppingcart.css'

import { ReactComponent as Sort } from './../assets/svg/scsort.svg'
import { ReactComponent as Checkout } from './../assets/svg/sccheckout.svg'
import { ReactComponent as MoreVertical } from './../assets/svg/morevertical.svg'

const MyShoppingCartContent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const cartDetailsIDs = useSelector(state => state.cartContent.cartDetailsIDs)
    const cartExists = localStorage.getItem('cart.uuid') && localStorage.getItem('cart.id')
    const cartDataloadedStatus = useSelector(state => state.cartContent.status)
    const cartSumm = parseFloat(useSelector(state => state.cartContent.summ))
    const cartSummOld = parseFloat(useSelector(state => state.cartContent.summold))
    const cartVat = parseFloat(useSelector(state => state.cartContent.vat)) || 0
    // const cartName = useSelector(state => state.cartContent.name)
    const cartContent = useSelector(state => state.cartContent.cartContent)
    const cartContentData = useSelector(state => state.cartContent)

    const moreMenuEl = useRef(null)
    const moreMenuOverlayEl = useRef(null)
    const cartRenameOrCreateNewRef = useRef(null)
    const lastElementRef = useRef(null)

    const [isLastElement, setLastElement] = useState(false)
    const [openAddProduct, setOpenAddProduct] = useState(false)
    const [orderID,setOrderID] = useState(0)
    const [orderIDReal, setOrderIDReal] = useState(0)
    const [orderMatchcode, setOrderMatchcode] = useState('')
    const [createdAt, setCreatedAt] = useState('')
    const [isOpenDropshipping, setIsOpenDropshipping] = useState(false)
    const [isSortingProducts, setIsSortingProducts] = useState(false)
    const [isSortingProductsClose, setIsSortingProductsClose] = useState(false)
    const [selectSortByItem, setSelectSortByItem] = useState('') 
    const [sortedCartContent, setSortedCartContent] = useState([])
    const [dropshippingData, setDropshippingData] = useState({})
    const [openDropshippingData, setOpenDropshippingData] = useState([])
    const [test, setTest] = useState('')
    const [isCreateNewCart, setIsCreateNewCart] = useState(false)
    const [isDeleteCartAlert, setIsDeleteCartAlert] = useState(false)
    const [isDeleteCartSuccess, setIsDeleteCartSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [delCartID, setDelCartID] = useState('')
    const [productId, setProductId] = useState('')


    /* scroll direction */
    const [y,setY] = useState(window.scrollY)
    const handleNavigation = useCallback(e => {
        const window = e.currentTarget
        y > window.scrollY ? document.body.classList.add('fixed_cart_summary') : document.body.classList.remove('fixed_cart_summary')
        setY(window.scrollY)
    },[y])

    useEffect(() => {
        setY(window.scrollY)
        window.addEventListener("scroll", handleNavigation)
        return () => {
            window.removeEventListener("scroll", handleNavigation)
        }
    }, [handleNavigation])
    /* scroll direction */

    useEffect(() => {
        if (openAddProduct) {
            setIsSortingProducts(false)
            setSelectSortByItem('')
            setSortedCartContent([])
        } else if (selectSortByItem && cartContent?.length > 0) {
            const resSort = returnSortedCartData(selectSortByItem, cartContent)
            setSortedCartContent(resSort)
        } 
    }, [selectSortByItem, openAddProduct, cartContent])

    const getCartInfo = () => {
        if (cartExists) {
            setIsLoading(true)
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-header/${localStorage.getItem('cart.id')}?include=${cartHeaderInclude.join(',')}&realKey=1&priceline_id=${localStorage.getItem('entity_account.priceline_id')}&priceline_external_id=${priceLineID}&warehouse_external_id=${localStorage.getItem('warehouse.id')}`, getRequestOptions())
            .then(response => response.json())
                .then(json => {
                    if (json.data) {
                        setOrderID(json.data.id)
                        setOrderMatchcode(json.data.matchcode)
                        setOrderIDReal(json.data.id_real)
                        setCreatedAt(json.data.readable_created_at)
                        dispatch(setCartHeaders({
                            id: json.data.id,
                            idreal: json.data.id_real,
                            name: json.data.matchcode || ''
                        }))
                        if (json?.data?.cartDetails?.data) {
                            dispatch(clearCartContent())
                            json?.data?.cartDetails?.data.forEach(e => {
                                const cartData = getCartProducts(e)
                                dispatch(addCartProduct(cartData))
                            })
                            dispatch(setIsLoaded('notloaded'))
                        }
                        setIsLoading(false)
                    }
            })
            .catch(error => {
                console.error(error)
                setIsLoading(false)
            })
            .finally(() => HideLoader())
        }
    }

    useEffect(() => {
        getCartInfo()
        cartContent.length === 0 && dispatch(setIsLoaded('loaded'))
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (cartContent.length) {
            dispatch(setIsLoaded('notloaded'))
        }
    // eslint-disable-next-line
    },[cartContent])

    const onCreateNewCart = (name) => {
        dispatch(setIsLoaded())
        ShowLoader()
        createNewCart(name)
            .then(response => response.json())
            .then(json => {
                json?.message && dispatch(setErrorMessage(json?.message))
                if (json.data) {
                    const cart = json.data
                    setOrderIDReal(cart.real_id)
                    setCreatedAt(cart.readable_created_at)
                    setCartIdsToStorage(cart.uuid, cart.id_real, cart.id)
                    dispatch(clearCartContent())
                    dispatch(clearCartIdName())
                    dispatch(setCartHeaders({
                        id: cart.id,
                        idreal: cart.id_real,
                        name: cart.matchcode || ''
                    }))
                }
            })
            .catch(error => console.error(error))
            .finally(() => {
                dispatch(setIsLoaded('notloaded'))
                HideLoader()
            }) 
    }

    const DeleteCart = e => {
        setDelCartID(e.target.dataset.id)
        setIsDeleteCartAlert(true)
        CloseMoreMenu()
    }

    useEffect(() => {
        const getCart = (cartUuid) => {

            fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-details?cart_header_uuid=${cartUuid}&include=${includeForProduct}&realKey=true&showVat=true&limit=1000&source_price_id[]=${priceLineID}&rand=${Math.random()}`, getRequestOptions())
            .then(response => response.json())
            .then(json => {
                dispatch(clearCartContent())
                json.data && json.data.forEach(e => {
                    const cartData = getCartProducts(e)
                    dispatch(addCartProduct(cartData))
                })
                dispatch(setIsLoaded('notloaded'))
            })
            .catch(error => console.error(error))
        }

        if (isDeleteCartSuccess) {
            dispatch(setIsLoaded('loaded'))
            try {
                ShowLoader()
                const URL = `${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id')}?include=status,company,cartDetails&realKey=1&sortBy=updated_at`
    
                getAllCartsData(URL)
                    .then(json => {                      
                        let updateDate = 0
                        let lastCart = {}
                        if (json.data && json.data.length > 0) {
                            json.data.forEach(e => {
                                const date = new Date(e.updated_at).getTime()
                                    if (updateDate < date) {
                                        updateDate = date
                                        lastCart = e
                                    }
                                }
                            )
                            
                            dispatch(setCartHeaders({
                                id: lastCart.id,
                                idreal: lastCart.id_real,
                                name: lastCart.matchcode || ''
                            }))
                            setOrderID(lastCart.id)
                            setOrderMatchcode(lastCart.matchcode)
                            setOrderIDReal(lastCart.id_real)
                            setCreatedAt(lastCart.readable_created_at)
                            setCartIdsToStorage(lastCart.uuid, lastCart.id_real, lastCart.id)
                            getCart(lastCart.uuid)
                        }
                    })
            } catch (e) {
                    console.log(e.name + ': ' + e.message)
            } finally {
                HideLoader()
            }
            setIsDeleteCartSuccess(false)
            setIsLoading(false)
        }
        // eslint-disable-next-line
    }, [isDeleteCartSuccess])
    
    const confirmDeletion = () => {
        ShowLoader()
        setIsDeleteCartAlert(false)
        deleteCartsItem(delCartID)
        .then(response => {
            if (response.ok) {
                setIsDeleteCartSuccess(true)
                setOrderIDReal(null)
                dispatch(clearCartContent())
                dispatch(clearCartIdName())
                setIsLoading(true)
            }
        })
        .catch(error => console.error(error))
        .finally(() => {
            HideLoader()
        })
    }

    const showHideSortPanel = () => {
        if (isSortingProducts) {
            setIsSortingProductsClose(true)
        } else {
            setIsSortingProducts(true)
            setIsSortingProductsClose(false)
        }
    }

    const ShowMoreMenu = () => {
        moreMenuEl.current.classList.add('active')
        moreMenuOverlayEl.current.classList.add('active')
    }

    const CloseMoreMenu = () => {
        moreMenuEl.current.classList.remove('active')
        moreMenuOverlayEl.current.classList.remove('active')
    }

    const ShowRenamePopup = () => {
        CloseMoreMenu()
        cartRenameOrCreateNewRef.current.classList.add('active')
    }

    const CloseRenamePopup = (name) => {
        
        if (name !== undefined) {
            if (isCreateNewCart) {
                onCreateNewCart(name)
            } else if (name){
                setOrderMatchcode(name)
            }
        }
        setIsCreateNewCart(false)
        cartRenameOrCreateNewRef.current.classList.remove('active')
    }

    useEffect(() => {
        if (lastElementRef.current && cartContent.length > 0 && isLastElement) {
            lastElementRef.current.scrollIntoView({ behavior: 'smooth' })
            setLastElement(false)
        }
    }, [cartContent, isLastElement])

    const showProductsCompany = e => {
        const supplier = e.target.dataset.name
        const checked = openDropshippingData.includes(supplier)
        const res = openDropshippingData
        checked ? setOpenDropshippingData(res.filter(i => i !== supplier)) : setOpenDropshippingData(prev => [...prev, supplier])
        setTest(supplier)
        const WhatExpland = e.target.parentNode.parentNode
        WhatExpland.classList.toggle('active')
    }

    const onCheckout = () => {
        if (cartContent.filter(i => i.supplier !== companySupplier).length >= 1 && !isOpenDropshipping) {
            setIsSortingProducts(false)
            setIsOpenDropshipping(true)
            const group = Object.groupBy(cartContent, prod => prod.supplier)
            setDropshippingData(group)
        } else {
            // localStorage.setItem('suppliers_data', JSON.stringify(shoppingCartSummary))
            // window.location.href = '/checkout/'
            navigate('/checkout/')
        }
    }

    const ShowCreateNewCartPopup = () => {
        setIsCreateNewCart(true)
        CloseMoreMenu()
        cartRenameOrCreateNewRef.current.classList.add('active')
    } 

    return (
        <>
            <div className="shopping_cart__content">
                {cartExists || isLoading || cartDataloadedStatus === 'loaded'
                    ? <h1>{isLoading ? '' : orderIDReal}
                        {isLoading
                            ? ''
                            : orderMatchcode?.length > 0
                                ? ` ${orderMatchcode}`
                                : ` ${t('niki.b2b.cart.shoppingcart')}`
                        }
                        {isLoading
                            ? <span className="more_button"></span>
                            : <span className="more_button">
                                <button onClick={ShowMoreMenu}><MoreVertical /></button>
                                <div className="more_menu" ref={moreMenuEl}>
                                    <button onClick={ShowCreateNewCartPopup}>
                                        {t('niki.b2b.cartflow.createnewcart')}
                                    </button>
                                    <button onClick={ShowRenamePopup}>
                                        {t('niki.b2b.cartflow.rename')}
                                    </button>
                                    <button onClick={DeleteCart} data-id={localStorage.getItem('cart.id')}>
                                        {t('niki.b2b.shoppingcart.deletecart')}
                                    </button>
                                </div>
                            </span>
                        }
                    </h1>
                    : <h1>{t('niki.b2b.cart.shoppingcart')}</h1>
                }  
                {cartExists &&
                    <div className="shopping_cart__created">{t('niki.b2b.cart.created')}: <span>{createdAt}</span></div>
                }
                {(isLoading || cartDataloadedStatus === 'loaded')
                    ? <CartPreloader number="10" />
                    : isOpenDropshipping
                        ? <div className="shopping_cart__list">{Object.keys(dropshippingData).map((e, i) =>
                            <div key={i}>
                                <div className="shopping_cart__list__item_dropshipping">
                                    <div>
                                        <div className="shopping_cart_dropshipping_content">
                                            <div><strong>{e}</strong></div>
                                            <div><strong>{localStorage.getItem('currency.left')}{String(parseFloat(dropshippingData[e].reduce((s,item) => s+item.price*item.qty,0)).toFixed(2)).replace(',', '.')}</strong></div>
                                        </div>
                                        <div className="shopping_cart_dropshipping_content" style={{fontSize: 12}}>
                                            <p><strong>{t('niki.b2b.cartflow.delivery')}</strong> 1-5 {t('niki.b2b.debts.days')}</p>
                                            <p><strong>{t('niki.b2b.debts.skus')}</strong> {dropshippingData[e].reduce((s,item) => s+item.qty,0)}</p>
                                        </div>
                                    </div>
                                    <div><button className="shopping_cart__expland" data-productid={e.productid} data-name={e} onClick={showProductsCompany}></button></div>
                                </div>
                                {test && openDropshippingData.includes(e) && dropshippingData[e].map((e, i) => <ProductListItem key={i} e={e} i={i} showNumButton={false} onProductId={setProductId} />)}
                            </div>
                            )}
                        </div>
                        : <div className="shopping_cart__list">
                            {selectSortByItem && sortedCartContent.length > 0
                                ? sortedCartContent.map((e, i) => <ProductListItem key={i} e={e} i={i} showNumButton={true} onProductId={setProductId}/>)
                                : cartContent.map((e, i) => <ProductListItem key={i} e={e} i={i} showNumButton={true} onProductId={setProductId}/>)
                            }
                        </div>
                }
            </div>
            <div className="shopping_cart__summary">
                <div className="shopping_cart__summary__white">
                    <div className="shopping_cart__summary__white__first">
                        <div>{t('niki.b2b.cartflow.totalskus')}</div>
                        <div>{t('niki.b2b.orders.totalnet')}</div>
                        <div>{t('niki.b2b.orders.totalvat')}</div>
                        {cartSumm !== cartSummOld && <div className="red">{t('niki.b2b.cartflow.savings')}</div>}
                    </div>
                    <div className="shopping_cart__summary__white__second">
                        <div>{cartContentData.count}</div>
                        <div>{PriceFormat(cartSumm)}</div>
                        <div>{PriceFormat(cartVat)}</div>
                        {cartSumm !== cartSummOld && <div className="red">{PriceFormat(cartSummOld-cartSumm)}</div>}
                    </div>
                    <div className="shopping_cart__summary__white__third">
                        <div>
                            <div className="shopping_cart__cart_total">{t('niki.b2b.cart.carttotal')}</div>
                            <div className="shopping_cart__cart_summary_price">{PriceFormat(cartSumm+cartVat)}</div>
                        </div>
                    </div>
                </div>
                <div className="shopping_cart__summary__grey">
                    <button className={isSortingProducts ? "btn btn-lightgrey" : "sorting__products_button"} onClick={showHideSortPanel} disabled={isOpenDropshipping || cartContent.length === 0}>
                        <Sort />
                    </button>
                    <button className="btn btn-info" onClick={() => {
                        setOpenAddProduct(true)
                        setIsOpenDropshipping(false)
                    }}>
                        <strong>+</strong> {t('niki.b2b.cartflow.product')}
                    </button>
                    <button onClick={onCheckout} className={[`btn btn-primary btn-ellipsis shopping_cart__checkout`, cartExists ? 'exists' : 'not_exists'].join(' ')}>
                        {t('niki.b2b.cartflow.finalize')}<Checkout />
                    </button>
                </div>
                {isSortingProducts && <SortingProductsData isSortingProductsClose={isSortingProductsClose} setIsSortingProducts={setIsSortingProducts} selectSortByItem={selectSortByItem} setSelectSortByItem={setSelectSortByItem} />}
            </div>
            <div ref={lastElementRef}></div>
            {openAddProduct && <AddProductToCart setLastElement={setLastElement} setOpenAddProduct={setOpenAddProduct}  />}
            <div className="more_menu__overlay" onClick={CloseMoreMenu} ref={moreMenuOverlayEl}></div>
            <div className="rename_cart__wrapper" ref={cartRenameOrCreateNewRef}>
                <RenameCart orderID={orderID} orderIDReal={orderIDReal} closeRenamePopup={CloseRenamePopup} isCreateCart={isCreateNewCart}/>
                <div className="rename_cart__overlay" onClick={CloseRenamePopup}></div>
            </div>
            {isDeleteCartAlert && <DeleteCartAlert closeAlertPopup={() => setIsDeleteCartAlert(false)} confirmDeletion={confirmDeletion} />}
            {productId && <ProductDetails productID={productId} setProductId={setProductId} />}
        </>
    )
}

const CartPreloader = props => {
    const arrayTemp = [...Array(parseInt(props.number))]
    return (
        <>{arrayTemp.map((e,i)=><div key={`cartplaceholder${i}`} className="cart__content_placeholder content_placeholder"></div>)}</>
    )
}

export const MyShoppingCart = () => {
    return (
        <>
            <div className="breadcrumbs__wrapper">
                <BreadCrumbs itemlist={[{'url':'/myshoppingcart/','title':t('niki.b2b.cart.shoppingcart')}]} />
                <ol className="breadcrumbs__steps">
                    <li className="current">{t('niki.b2b.cartflow.cart')}</li>
                    <li></li>
                    <li></li>
                </ol>
            </div>
            <div className="content">
                <MyShoppingCartContent />
            </div>
        </>
    )
}