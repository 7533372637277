import { useState, useEffect } from "react"
import { BreadCrumbs } from "../components/BreadCrumbs"

import { t } from "i18next"
import { getRequestOptions } from '../helpers/apiHelper'

const MandatoryDisclosuresContent = ({auth}) => {
    const [data, setData] = useState('')
    const [dataLoaded, setDataLoaded] = useState(false)
    
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/${auth}`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            json.data && setData(json.data[0].body)
            json.data && setDataLoaded(true)
        })
    // eslint-disable-next-line
    }, [])
    
    return (
        <div className="static_page_content">
            <h1>{t('niki.b2b.footer.mandatorydisclosures')}</h1>
            {!dataLoaded
                ? <ContentPlaceholder />
                : <div className="dangerouslyInnerHTM__container_text" dangerouslySetInnerHTML={{ __html: data }} />
            }
        </div>
    )
}

export const MandatoryDisclosures = ({auth}) => {
    return (
        <div className="content">
            <BreadCrumbs itemlist={[{ 'url': '/mandatory-disclosures/', 'title': t('niki.b2b.footer.mandatorydisclosures') }]} />
            <MandatoryDisclosuresContent auth={auth} />
        </div>
    )
}

const ContentPlaceholder = () => {
    return (
        <>
            <div className="animated_placeholder marginbottom15em width25"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom2em width75"></div>
            <div className="animated_placeholder marginbottom15em width25"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom2em width75"></div>
            <div className="animated_placeholder marginbottom15em width25"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom1em"></div>
            <div className="animated_placeholder marginbottom2em width75"></div>
        </>
    )
}