import React, {useEffect,useState} from "react"
import { useParams, NavLink } from "react-router-dom"
import { t } from "i18next"
import { getRequestOptions, getAllCartsData } from '../helpers/apiHelper'

import { BreadCrumbs } from "../components/BreadCrumbs"
import { TwoContacts } from "./../components/TwoContacts"
import LoadingDots from "../components/LoadingDots/LoadingDots"

import { ReactComponent as Orders } from './../assets/svg/gridorders.svg'
import { ReactComponent as Account } from './../assets/svg/gridaccount.svg'
import { ReactComponent as ShoppingCarts } from './../assets/svg/gridshoppingcarts.svg'

const FeedbackCompleteContent = props => {
    const feedbackID = props.feedbackID
    const [realID, setRealID] = useState(null)
    const [cartsQuantity, setCartsQuantity] = useState(0)

    useEffect(()=>{
        try {
            getAllCartsData(`${process.env.REACT_APP_API_DOMAIN}/b2b/cart-headers/${localStorage.getItem('account.entity.id')}`)
                .then(json => json.data && setCartsQuantity(parseInt(json.data.length)))
        } catch (error) {
            console.error(error)
        }
    }, [])
    
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_DOMAIN}/b2b/feedbacks/${feedbackID}?realKey=1`,getRequestOptions())
        .then(response => response.json())
        .then(json => {
            json.data && setRealID(json.data.id_real)
        })
        .catch(error => console.error(error))
    }, [feedbackID])
    
    return (
        <>
            <BreadCrumbs itemlist={[
                {'url':'/account/','title':t('niki.b2b.menu.myaccount')},
                {'url':'/feedback/','title':t('niki.b2b.menu.feedback')},
                {'url':`/feedback-recieved/${feedbackID}/`,'title':t('niki.b2b.returnproducts.complete')},
            ]} />
            <div className="feedback_content">
                <h1>{t('niki.b2b.feedback.received')}</h1>

                <div className="feedback_red">
                    {realID
                        ? <h2> {t('niki.b2b.menu.feedback')} {realID}</h2>
                        : <div className={["wrapper_loading_dots", "wrapper_loading_dots_feedback"].join(' ')}>
                            <h2>{t('niki.b2b.menu.feedback')}</h2><LoadingDots />
                        </div>
                    }
                    <div>{t('niki.b2b.feedback.received1')}<br />{t('niki.b2b.feedback.received2')}</div>
                </div>

                <h2>{t('niki.b2b.feedback.otheroptons')}</h2>
                <ul className="feedback_grid">
                    <li><NavLink to="/account/"><Account />{t('niki.b2b.menu.myaccount')}</NavLink></li>
                    <li><NavLink to="/orders/"><Orders />{t('niki.b2b.account.orders')}</NavLink></li>
                    <li><NavLink to="/shoppingcarts/"><ShoppingCarts />{t('niki.b2b.cart.shoppingcarts')}</NavLink>
                        {cartsQuantity > 0 && <span className="alert">{cartsQuantity}</span>}
                    </li>
                </ul>

                <div className="your_designated_support_team">{t('niki.b2b.account.supportteam')}</div>
                <TwoContacts />
            </div>
        </>
    )
}

export const FeedbackComplete = () => {
    const { id } = useParams()
    return (
        <div className="content">
            <FeedbackCompleteContent feedbackID={id} />
        </div>
    )
}
